import { IConfigElementSimple } from '@/models/preset'
export const paymentsTabAttributesConfig: IConfigElementSimple = {
  name: 'Key-value блок таба Доп. информация',
  type: 'ContainerPreset',
  widgetId: 'paymentsRegistry_tabs_paymentAttributes_container',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Заголовок группы полей Плательщик',
      type: 'TextPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_payer_header_text',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Код банка',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_payer_payerBankCode',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Банк',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_payer_payerBankName',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Счет банка',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_payer_payerBankCoracc',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Счет',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_payer_payerAccount',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Наименование',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_payer_payerName',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле коды',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_payer_payerCodes',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Заголовок группы полей Получатель',
      type: 'TextPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_1_receiver_header_text',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Наименование счета',
      type: 'KVInfoBlockItemPreset',
      widgetId:
        'paymentsRegistry_tabs_paymentAttributes_container_column_1_receiver_receiverAccount_name',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Банк',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_2_bank',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Счет',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_2_account',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Валюта счета',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_2_currency',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Счет Axapta',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_2_nameAxapta',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Заголовок Техническая информация',
      type: 'TextPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_3_techInformation',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Код пакета',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_3_packageId',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Загружен',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_3_createdAt',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле ID платежа в Axapta',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_3_axaptaId',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Заголовок Выгружен из Axapta',
      type: 'TextPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_3_axaptaCreateDateTime',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле контрагента Axapta',
      type: 'KeyValueStringPreset',
      widgetId: 'paymentsRegistry_tabs_paymentAttributes_container_column_3_axaptaAccountNum',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
  ],
}
