import { IConfigElementSimple } from '@/models/preset'

export const drawerIdentificationConfig: IConfigElementSimple = {
  name: 'Дровер Ручная идентификация платежа',
  type: 'Drawer',
  widgetId: 'paymentsRegistry_drawer_manualIdentification',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Поле Код платежа',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_id',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле Номер платежа',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_docNumber',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле Дата поступления',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_incomingDate',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле Сумма в руб.',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_sum',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле Плательщик',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_payerName',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле ИНН/КПП',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_payerCodes',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле Назначение',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_purpose',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Поле Примечание',
      type: 'KVInfoBlockItemPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_note',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Заголовок Добавление заявки/идентификации',
      type: 'TextPreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_add_label',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Таблица реестра переводов',
      type: 'TablePreset',
      widgetId: 'paymentsRegistry_drawer_manualIdentification_table_add',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'Колонка Субъект',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_drawer_manualIdentification_table_add_subject',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'Колонка Договор',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_drawer_manualIdentification_table_add_contract',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'Колонка Статус договора',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_drawer_manualIdentification_table_add_status',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'Колонка Прим.',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_drawer_manualIdentification_table_add_note',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'Колонка Кнопка добавления идентификации',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_drawer_manualIdentification_table_add_identification',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'Колонка Кнопка добавления заявки',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_drawer_manualIdentification_table_add_ticket',
          prefs: {
            label: null,
            hidden: 'true',
            disabled: null,
            editable: null,
          },
          children: [],
        },
      ],
    },
  ],
}
