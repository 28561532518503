import { IContourConfiguration } from '@/models/entities/contour'
import { defineStore } from 'pinia'
import { ref, type Ref, computed } from 'vue'
import api from '@/api'
import { deleteForwardRoute } from '@/utils/routerHelpers'
import { ILoginRequestData, IProfile } from '@/models/auth'
import { uniqBy } from 'lodash'
import { IContoursRoles, IContour } from '@/models/entities/contour'

export const useAuthStore = defineStore('auth', () => {
  const currentProfile: Ref<IProfile | null> = ref(null)
  const defaultProfile: Ref<IProfile | null> = ref(null)

  const contourConfiguration: Ref<IContourConfiguration | null> = ref(null)

  function setIsAuth(value: boolean) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    localStorage.setItem('isAuth', `${value}`)
  }

  function getIsAuth(): boolean {
    return localStorage.getItem('isAuth') === 'true'
  }

  function setCurrentProfile(profile: IProfile | null) {
    currentProfile.value = profile
    saveSelectedProfileToLocalStorage(profile)
  }

  function saveSelectedProfileToLocalStorage(profile: IProfile | null) {
    if (profile) {
      localStorage.setItem('currentProfile', JSON.stringify(profile))
    } else {
      localStorage.removeItem('currentProfile')
    }
  }

  function setDefaultProfile(profile: IProfile | null) {
    defaultProfile.value = profile
  }

  function setContourConfiguration(configuration: IContourConfiguration | null): void {
    contourConfiguration.value = configuration
  }

  async function makeLogin(requestData: ILoginRequestData) {
    const response = await api.auth.login(requestData)
    setIsAuth(true)
    return response
  }

  async function makeLogout() {
    await api.auth.logout()
    setIsAuth(false)
    setCurrentProfile(null)
    setDefaultProfile(null)
    deleteForwardRoute()
  }

  const isAppInitialazing = ref<boolean>(false)

  const contoursRoles = ref<IContoursRoles[]>([])
  const mappedContours = computed(() => {
    return contoursRoles.value.map(contour => {
      return {
        contourId: contour.contourId,
        contourName: contour.contourName,
        roleId: contour.roleId,
        value: contour.contourId,
      }
    })
  })

  const uniqContours = computed(() => {
    return uniqBy(mappedContours.value, 'contourId')
  })

  const fetchAvailableContoursRoles = async () => {
    try {
      const { list } = await api.users.getAvailableContourRoles()
      contoursRoles.value = list.map(contour => {
        const { contourId, ...data } = contour
        return { contourId: String(contourId), ...data }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const findContourRoleByContourId = (contourId: IContour['id']) => {
    const contour = contoursRoles.value.find(contour => contour.contourId === contourId)
    return contour
  }

  return {
    makeLogin,
    makeLogout,
    setIsAuth,
    getIsAuth,
    setCurrentProfile,
    setDefaultProfile,
    saveSelectedProfileToLocalStorage,
    setContourConfiguration,
    currentProfile,
    defaultProfile,
    contourConfiguration,
    isAppInitialazing,
    contoursRoles,
    mappedContours,
    uniqContours,
    fetchAvailableContoursRoles,
    findContourRoleByContourId,
  }
})

export type AuthStore = ReturnType<typeof useAuthStore>
