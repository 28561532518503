import request from '@/api/request'
import {
  IParameterValuesAdd,
  IFinancialParameterValueResponse,
  IGetParameterValuesQueryParams,
} from '@/models/entities/financialParameters'

export const getParameterValues = (data?: IGetParameterValuesQueryParams) => {
  return request<Record<'list', IFinancialParameterValueResponse[]>>({
    url: `/api/v1/parameter_values`,
    method: 'GET',
    params: data,
  })
}

export const postParameterValues = (data?: IParameterValuesAdd) => {
  return request<Record<'list', IFinancialParameterValueResponse[]>>({
    url: `/api/v1/parameter_values`,
    method: 'POST',
    data,
  })
}
