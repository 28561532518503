import { defineStore } from 'pinia'
import { ref } from 'vue'
import { socketService } from '@/utils/socket'
import { INotification } from '@/models/entities/notification.ts'
import { useFetch } from '@/composables/useFetch.ts'
import api from '@/api'
import type { DataTableFilterState } from 'naive-ui'

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<INotification[]>([])
  const unreadCount = ref(0)
  const notificationsCount = ref(0)
  const isLoading = ref(false)

  const subscribeToNotifications = async () => {
    try {
      await socketService.connect()
      socketService.onNotification(handleNewNotification)
    } catch (error) {
      console.error('Error connecting to WebSocket:', error)
    }
  }

  const handleNewNotification = () => {
    unreadCount.value++
  }

  const fetchNotifications = async (preparedFilters: DataTableFilterState) => {
    try {
      const { execute } = useFetch(api.notification.getNotifications, isLoading)
      const { list = [], count } = await execute(preparedFilters)
      notifications.value = list
      notificationsCount.value = count
    } catch (error) {
      console.error('Error fetching notifications:', error)
    }
  }

  return {
    notifications,
    notificationsCount,
    unreadCount,
    isLoading,
    subscribeToNotifications,
    fetchNotifications,
  }
})
