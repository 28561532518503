<template>
  <router-link
    v-if="preparedRouteLinkPath"
    :to="preparedRouteLinkPath"
    :target="isTargetBlank ? '_blank' : '_self'"
    @click.stop
  >
    {{ preparedValue }}
  </router-link>
  <span v-else>{{ preparedValue }}</span>
</template>

<script setup lang="ts">
import { computed, unref } from 'vue'
import { CONFIG_ELEMENT_TYPES, IConfigElement } from '@/models/preset'
import { usePreset } from '@/composables/usePreset'
import { get } from 'lodash'
import { IProfile } from '@/models/auth'
import { computeRoutePath } from '@/utils/baseHelpers'
import { resolveRouteAndEnrichPath } from '@/utils/routerHelpers'
import { useTranslationStore } from '@/stores/translation'
import { Nullable } from '@/models/helpers.ts'

const props = withDefaults(
  defineProps<{
    value?: string | number | null
    config?: IConfigElement<CONFIG_ELEMENT_TYPES.TableColumnPreset> | null
    routeLinkPath?: string | null
    profile?: Nullable<IProfile>
    newTab?: boolean
    defaultValue?: string
  }>(),
  {
    value: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config: () => null,
    routeLinkPath: null,
    profile: null,
    newTab: false,
    defaultValue: '-',
  },
)
const { config } = props

const translationStore = useTranslationStore()

const isTargetBlank = computed(() => {
  return props.newTab || config?.prefs.presetParams?.custom?.newTab
})

const { state } = config ? usePreset(config) : {}

const preparedDefaultValue = computed(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return props.config?.prefs.options?.defaultValue ?? props.defaultValue
})

const preparedValue = computed(() => {
  let prepValue = props.value ?? null

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (config?.dataFieldPath && state?.data?.value) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    prepValue = get(unref(state.data.value), config.dataFieldPath)
  }
  return typeof prepValue === 'string'
    ? translationStore.translateString(prepValue) || preparedDefaultValue.value
    : (prepValue ?? preparedDefaultValue.value)
})

const preparedRouteLinkPath = computed(() => {
  let prepRouteLinkPath = props.routeLinkPath
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const routePathFromConfig = config?.prefs.options?.routeLinkPath
  if (routePathFromConfig) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    prepRouteLinkPath = computeRoutePath(routePathFromConfig, unref(state?.data?.value))
  }
  if (prepRouteLinkPath) {
    prepRouteLinkPath = resolveRouteAndEnrichPath(prepRouteLinkPath, props.profile)
  }
  return prepRouteLinkPath
})
</script>
