import { io, Socket } from 'socket.io-client'

type NotificationCallback = () => void

let socket: Socket | null = null
const notificationsCallbacks: NotificationCallback[] = []

const WS_RECONNECTION_DELAY = 1000
const WS_RECONNECTION_TIMEOUT = 10000

const connect = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const WS_HOST =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (import.meta.env.VITE_DEV_API_HOST as string) ||
      'https://front-gate.hg3-test.dev.k8s.id-network.ru/'
    const wsUrl = `${WS_HOST}notifications`

    socket = io(wsUrl, {
      withCredentials: true,
      reconnection: true,
      reconnectionDelay: WS_RECONNECTION_DELAY,
      timeout: WS_RECONNECTION_TIMEOUT,
    })

    socket.on('notification', () => {
      notificationsCallbacks.forEach(callback => {
        callback()
      })
    })

    socket.on('connect', () => {
      resolve()
    })

    socket.on('connect_error', (error: Error) => {
      console.error('Connection error:', error)
      reject(error)
    })
  })
}

const onNotification = (callback: NotificationCallback): void => {
  notificationsCallbacks.push(callback)
}

const disconnect = (): void => {
  if (socket) {
    socket.disconnect()
  }
}

export const socketService = {
  connect,
  onNotification,
  disconnect,
}
