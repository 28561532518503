<template>
  <n-badge
    :value="notificationStore.unreadCount"
    :max="10"
  >
    <IconButtonPreset
      :icon-component="Bell"
      color="#fff"
      size="large"
      circle
      @click="openNotificationsRegistry"
    />
  </n-badge>
</template>

<script setup lang="ts">
import { Bell } from '@vicons/tabler'
import { useRouter } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications.ts'
import { onMounted } from 'vue'

const router = useRouter()
const notificationStore = useNotificationsStore()

onMounted(async () => {
  await notificationStore.subscribeToNotifications()
})

const openNotificationsRegistry = async () => {
  await router.push({ name: 'NotificationsRegistry' })
}
</script>

<style scoped></style>
