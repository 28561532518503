<template>
  <n-space align="center">
    <n-popselect
      v-model:value="contourId"
      trigger="click"
      :options="authStore.uniqContours"
      label-field="contourName"
      @update:value="onChangeContour"
    >
      <n-button
        icon-placement="right"
        text
        color="#FFF"
      >
        <template #icon>
          <n-icon>
            <ChevronDownOutline />
          </n-icon>
        </template>
        {{ currentContour?.contourName }}
      </n-button>
    </n-popselect>
    <n-divider vertical />
    <n-popselect
      v-model:value="roleId"
      trigger="click"
      :options="mappedRoles"
      label-field="roleName"
      @update:value="onChangeRole"
    >
      <n-button
        icon-placement="right"
        text
        color="#FFF"
      >
        <template #icon>
          <n-icon>
            <ChevronDownOutline />
          </n-icon>
        </template>
        {{ currentRole?.roleName }}
      </n-button>
    </n-popselect>
  </n-space>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { uniqBy, merge } from 'lodash'
import { useAuthStore } from '@/stores/auth'
import api from '@/api'
import { ChevronDownOutline } from '@vicons/ionicons5'
import { useRouter, useRoute } from 'vue-router'
import { createQueryWithProfileData, PROFILE_CODES } from '@/utils/routerHelpers'

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()

onMounted(async () => {
  await authStore.fetchAvailableContoursRoles()
  initContourAndRole()
})

function initContourAndRole() {
  if (typeof authStore.currentProfile?.contourId === 'string') {
    contourId.value = authStore.currentProfile.contourId
  }
  if (typeof authStore.currentProfile?.roleId === 'string') {
    roleId.value = authStore.currentProfile.roleId
  }
}

const contourId = ref<string | null>(null)
const roleId = ref<string | null>(null)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const onChangeContour = id => {
  if (currentContour.value) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setProfile(id, roleId.value)
  } else {
    const updatedContourRole = authStore.findContourRoleByContourId(id)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    roleId.value = updatedContourRole.roleId

    // @ts-expect-error TODO refactoring
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setProfile(contourId.value, updatedContourRole.roleId)
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const onChangeRole = id => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  setProfile(contourId.value, id)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const setProfile = async (contourId, roleId) => {
  if (!contourId || !roleId) return
  try {
    await api.auth.setProfile({ contourId, roleId })
    authStore.setCurrentProfile({ contourId, roleId })

    if (route.query[String(PROFILE_CODES.contourId)] || route.query[String(PROFILE_CODES.roleId)]) {
      const newProfileQuery = Object.fromEntries(
        createQueryWithProfileData({ contourId, roleId }).entries(),
      )
      await router.replace({ query: merge(merge({}, route.query), newProfileQuery) })
    }
    router.go(0)
  } catch (e) {
    console.error(e)
  }
}

const currentContour = computed(() =>
  authStore.mappedContours.find(
    contour => contour.contourId === contourId.value && contour.roleId === roleId.value,
  ),
)

const currentContourRoles = computed(() =>
  authStore.contoursRoles.filter(contour => contour.contourId === contourId.value),
)

const mappedRoles = computed(() => {
  return uniqBy(currentContourRoles.value, 'roleId').map(contour => {
    return {
      roleName: contour.roleName,
      value: contour.roleId,
    }
  })
})

const currentRole = computed(() => mappedRoles.value.find(role => role.value === roleId.value))
</script>
