import request, { IResponseList } from '@/api/request.ts'
import {
  IGetNotificationsQueryParams,
  INotification,
  INotificationStatus,
  INotificationTemplateComponent,
  INotificationTemplateHeader,
} from '@/models/entities/notification.ts'
import { ICommonListQueryParams } from '@/models/entities/common.ts'

export const getNotifications = (params?: IGetNotificationsQueryParams) => {
  return request<IResponseList<INotification>>({
    url: '/api/v1/notification/notifications',
    params,
  })
}

export const getNotificationHeaders = (params?: ICommonListQueryParams) => {
  return request<IResponseList<INotificationTemplateHeader>>({
    url: '/api/v1/notification/headers',
    params,
  })
}

export const getNotificationComponents = (params?: ICommonListQueryParams) => {
  return request<IResponseList<INotificationTemplateComponent>>({
    url: '/api/v1/notification/components',
    params,
  })
}

export const getNotificationStatuses = (params?: ICommonListQueryParams) => {
  return request<IResponseList<INotificationStatus>>({
    url: '/api/v1/notification/statuses',
    params,
  })
}
