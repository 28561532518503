<template>
  <div class="not-found-container">
    <h1>
      {{ translationStore.translateString('Страница не найдена') }}
    </h1>
    <n-icon size="40">
      <HappyOutline />
    </n-icon>
    <BtnLinkPreset
      class="mt-15"
      route-link-path="/"
      :value="translationStore.translateString('На главную')"
    />
  </div>
</template>

<script setup lang="ts">
import { HappyOutline } from '@vicons/ionicons5'
import { useTranslationStore } from '@/stores/translation'

const translationStore = useTranslationStore()
</script>

<style scoped>
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
</style>
