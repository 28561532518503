import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  IContract,
  IContractNetwork,
  ICreateContractData,
  ICreateContractUseobjectData,
  IContractChain,
  IContractProjectUseobject,
  ICreateContractFromProjectData,
  IContractSearch,
  IGetSearchContractParams,
  IGetContractStatusesQueryParams,
  IContractStatus,
  IContractUseobjectContract,
  ICopyContractProjectData,
  IContractRegion,
  IContractDoctype,
  IGetContractDocTypesQueryParams,
  IGeneratedChildContractNumber,
} from '@/models/entities/contract'
import { ICreateOperatorChain } from '@/api/contract/models'

export const getContracts = (params?: unknown) => {
  return request<IResponseList<IContract>>({
    url: '/api/v1/contract/contracts',
    method: 'GET',
    params,
  })
}

export const getContract = (id: string) => {
  return request<IContract>({
    url: `/api/v1/contract/contracts/${id}`,
    method: 'GET',
  })
}

export const searchContracts = (params?: IGetSearchContractParams) => {
  return request<IResponseList<IContractSearch>>({
    url: `/api/v1/contract/contracts/search`,
    method: 'GET',
    params,
  })
}

export const createContract = (data: ICreateContractData) => {
  return request({
    url: '/api/v1/contract/contracts/create',
    method: 'POST',
    data,
  })
}

export const createContractFromProject = (data: ICreateContractFromProjectData) => {
  return request<IResponseEntity<IContract>>({
    url: '/api/v1/contract/contracts/create_from_project',
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getOrgUnits = data => {
  return request({
    url: '/api/v1/contract/org-units',
    method: 'GET',
    params: data,
  })
}

export const getContractStatuses = (params?: IGetContractStatusesQueryParams) => {
  return request<IResponseList<IContractStatus>>({
    url: '/api/v1/contract/statuses',
    method: 'GET',
    params,
  })
}

export const getContractNetworks = (id: string) => {
  return request<IResponseEntity<IContractNetwork>>({
    url: `/api/v1/contract/contracts/get_networks/${id}`,
    method: 'GET',
  })
}

export const getContractUseobjects = (params?: unknown) => {
  return request<IResponseList<IContractProjectUseobject>>({
    url: `/api/v1/contract/project_useobjects`,
    method: 'GET',
    params,
  })
}

export const createContractUseobject = (data: ICreateContractUseobjectData) => {
  return request({
    url: '/api/v1/contract/project_useobjects/create',
    method: 'POST',
    data,
  })
}

export const deleteContractUseobject = (id: string) => {
  return request({
    url: `/api/v1/contract/contract_useobjects/${id}`,
    method: 'DELETE',
  })
}

export const getContractChains = (params?: unknown) => {
  return request<IResponseList<IContractChain>>({
    url: '/api/v1/contract/chains/search',
    method: 'GET',
    params,
  })
}

export const createOperatorChain = (data: ICreateOperatorChain) => {
  return request<IResponseEntity<ICreateOperatorChain>>({
    url: `/api/v1/contract/chains/create`,
    method: 'POST',
    data,
  })
}

export const getUseobjectContracts = (useobjectId: string) => {
  return request<IResponseList<IContractUseobjectContract>>({
    url: `/api/v1/contract/useobjects/${useobjectId}/contracts`,
  })
}

export const copyProject = (data: ICopyContractProjectData) => {
  return request<IContractSearch>({
    url: `/api/v1/contracts/copy_project`,
    method: 'POST',
    data,
  })
}

export const getContractRegions = (id: string) => {
  return request<IResponseList<IContractRegion>>({
    url: `/api/v1/contract/contracts/get_regions/${id}`,
    method: 'GET',
  })
}

export const getContractDocTypes = (params?: IGetContractDocTypesQueryParams) => {
  return request<IResponseList<IContractDoctype>>({
    url: '/api/v1/doctypes',
    method: 'GET',
    params,
  })
}

export const generateChildContractNumber = (id: IContractSearch['id']) => {
  return request<IGeneratedChildContractNumber>({
    url: `/api/v1/contract/contracts/child_contract_generate_number/${id}`,
    method: 'GET',
  })
}
