import { IConfigElementSimple } from '@/models/preset'
export const paymentsTabOperationsConfig: IConfigElementSimple = {
  name: 'таб Учет',
  type: 'ContainerPreset',
  widgetId: 'paymentsRegistry_tabs_operations',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'кнопка Госпошлина',
      type: 'ButtonPreset',
      widgetId: 'paymentsRegistry_tabs_operations_button_duty',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'кнопка Исключение из учета',
      type: 'ButtonPreset',
      widgetId: 'paymentsRegistry_tabs_operations_button_deny',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'кнопка Прочие сборы',
      type: 'ButtonPreset',
      widgetId: 'paymentsRegistry_tabs_operations_button_another',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Таблица таба Операции',
      type: 'TablePreset',
      widgetId: 'paymentsRegistry_tabs_operations_table',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'колонка Операция',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_column_operation',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Субъект',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_column_subject',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Договор',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_column_contract',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Вид',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_column_operationSubtype',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '110px',
            },
          },
          children: [],
        },
        {
          name: 'колонка Сумма',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_column_sum',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Дата учёта',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_column_accountingDate',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Прим.',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_column_note',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка действий',
          type: 'TableColumnPreset',
          widgetId: 'paymentsRegistry_tab_operation_table_actions',
          prefs: {
            label: null,
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
      ],
    },
  ],
}
