import {
  type RouteLocationNormalized,
  LocationQuery,
  useRouter,
  RouteLocationRaw,
} from 'vue-router'
import { CONFIRMATION_ROUTE_NAME, LOGIN_ROUTE_NAME, PROFILES_ROUTE_NAME } from '@/router/constants'
import { IProfile } from '@/models/auth'
import { AuthStore, useAuthStore } from '@/stores/auth'
import { Nullable } from '@/models/helpers'

type UnsavedPages = string | symbol
/**
 * Массив наименований путей, которые не должны быть сохранены
 * @type {string[]}
 */
const UNSAVED_PAGES: UnsavedPages[] = [
  PROFILES_ROUTE_NAME,
  LOGIN_ROUTE_NAME,
  CONFIRMATION_ROUTE_NAME,
]

const PAGE_NOT_FOUND_PATH = '/404'

//Сохраняет путь для редиректа после авторизации
export const saveForwardRoute = (route: RouteLocationNormalized) => {
  const { path, params, query, hash, name } = route
  const forwardRoute = { path, params, query, hash, name }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (UNSAVED_PAGES.includes(forwardRoute.name)) return

  const serializedForwardRoute = JSON.stringify(forwardRoute)
  sessionStorage.setItem('forwardRoute', serializedForwardRoute)
}

export const deleteForwardRoute = () => {
  sessionStorage.removeItem('forwardRoute')
}

export const getForwardRoute = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return JSON.parse(sessionStorage.getItem('forwardRoute'))
}

export function windowOpenBlank(href: string, profile?: IProfile | null) {
  const authStore: AuthStore = useAuthStore()
  authStore.saveSelectedProfileToLocalStorage(profile || authStore.currentProfile)
  // eslint-disable-next-line custom-rules/no-window-open
  window.open(href, '_blank')
}

export const PROFILE_CODES: Record<'contourId' | 'roleId', string> = {
  contourId: 'ci',
  roleId: 'ri',
}

export function getProfileFromRouteQuery(query?: LocationQuery): IProfile | undefined {
  if (!query || !query[PROFILE_CODES.contourId] || !query[PROFILE_CODES.roleId]) return
  const profile: IProfile = {
    contourId: query[PROFILE_CODES.contourId] as string,
    roleId: query[PROFILE_CODES.roleId] as string,
  }
  return profile
}

function enrichPathWithProfileQuery(path: string, profile?: Nullable<IProfile>) {
  let newPath = path || PAGE_NOT_FOUND_PATH
  const authStore: AuthStore = useAuthStore()
  const linkProfile = profile || authStore.currentProfile
  if (!linkProfile) return newPath
  const profileQueryParams: URLSearchParams = createQueryWithProfileData(linkProfile)
  const additionalQuerySymbol = newPath.includes('?') ? '&' : '?'
  return `${newPath}${additionalQuerySymbol}${profileQueryParams.toString()}`
}

export function createQueryWithProfileData(profile?: Nullable<IProfile>): URLSearchParams {
  const authStore: AuthStore = useAuthStore()
  const linkProfile = profile || authStore.currentProfile
  const profileQueryParams: URLSearchParams = new URLSearchParams()
  if (!linkProfile) return profileQueryParams
  Object.entries(linkProfile).forEach(([key, value]) => {
    const profileKey = key as 'contourId' | 'roleId'
    profileQueryParams.set(PROFILE_CODES[profileKey], String(value))
  })
  return profileQueryParams
}

function getPath(to: RouteLocationRaw): string {
  const router = useRouter()
  let resolved
  try {
    if (typeof to === 'string') {
      resolved = to
    }
    resolved = router.resolve(to)
  } catch {}
  return typeof resolved === 'string' ? resolved : resolved?.href || PAGE_NOT_FOUND_PATH
}

export function resolveRouteAndEnrichPath(
  to: RouteLocationRaw,
  profile?: Nullable<IProfile>,
): string {
  return enrichPathWithProfileQuery(getPath(to), profile)
}
