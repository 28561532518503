import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
  const breadcrumb = ref([])
  const tempStore = ref({})

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const buildBreadcrumb = config => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    breadcrumb.value = config.map(el => {
      let text = ''
      const options = el.text.options

      const type = el.text.type
      if (type === 'simple') {
        text = options.value
      }

      if (type === 'fromTempStore') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        text = computed(() => tempStore.value[options.field])
      }

      return {
        name: el.name,
        text,
        to: el.to,
      }
    })
  }

  // deprecated
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const patchBreadcrumbItem = payload => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    breadcrumb.value = breadcrumb.value.map(el => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (el.name !== payload.name) {
        return el
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return { name: el.name, text: payload.text }
    })
  }

  const clearBreadcrumb = () => {
    breadcrumb.value = []
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const patchTempStore = payload => {
    tempStore.value = { ...tempStore.value, ...payload }
  }

  const clearTempStore = () => {
    tempStore.value = {}
  }

  return {
    breadcrumb,
    buildBreadcrumb,
    patchBreadcrumbItem,
    clearBreadcrumb,

    tempStore,
    patchTempStore,
    clearTempStore,
  }
})
