<template>
  <n-config-provider
    :theme="theme"
    :theme-overrides="themeOverrides"
    :locale="ruRU"
    :date-locale="dateRuRU"
  >
    <n-dialog-provider>
      <div
        v-if="authStore.isAppInitialazing"
        class="loaderWrapper"
      >
        <n-spin size="large" />
      </div>
      <!-- @vue-expect-error -->
      <component
        :is="layoutComponentsMap[layout]"
        v-else-if="layout"
        :key="layout"
      >
        <n-theme-editor v-if="showThemeEditor">
          <router-view :key="routeAppKey" />
        </n-theme-editor>
        <router-view
          v-else
          :key="routeAppKey"
        />
      </component>
      <router-view
        v-else
        :key="routeAppKey"
      />
    </n-dialog-provider>
  </n-config-provider>
</template>

<script setup>
import { NThemeEditor, darkTheme, lightTheme, ruRU, dateRuRU } from 'naive-ui'
import { useUiStore } from '@/stores/ui'
import { computed, onBeforeMount, ref, provide, onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useLocalStorage } from '@/composables/useLocalStorage'
import MainLayout from '@/layouts/main/LayoutsMainContent.vue'
import AuthLayout from '@/layouts/auth/LayoutsAuthContent.vue'
import { useAuthStore } from '@/stores/auth'
import { makeColorsPack, globalRegister } from '@/utils/theme'
import { useTranslationStore } from '@/stores/translation'

const authStore = useAuthStore()
const translationStore = useTranslationStore()
const route = useRoute()
const uiStore = useUiStore()

const themeColors = ref(null)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const setThemeColors = color => {
  if (!color) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    themeColors.value = makeColorsPack(localStorage.getItem('theme-color') || '#18A058')
    return globalRegister(themeColors.value)
  }

  localStorage.setItem('theme-color', color)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  themeColors.value = makeColorsPack(localStorage.getItem('theme-color'))
  globalRegister(themeColors.value)
}

onBeforeMount(() => {
  setThemeColors()
  setLanguage()
})

provide('theme', { themeColors, setThemeColors })

let themeEditor = useLocalStorage('hg3ThemeEditor')

const layout = computed(() => route.meta.layout)
const layoutComponentsMap = {
  'main-layout': MainLayout,
  'auth-layout': AuthLayout,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const showThemeEditor = computed(() => ['on', 'true'].includes(themeEditor.value))
const theme = computed(() => (uiStore.theme === 'light' ? lightTheme : darkTheme))
// здесь подключается json, экспортированный из themeEditor
// const themeOverrides = themeConfig
const themeOverrides = computed(() => ({
  common: {
    ...(themeColors.value || {}),
    textColorDisabled: uiStore.theme === 'light' ? '#000000' : '#FFFFFF',
  },
}))

const setLanguage = async () => {
  translationStore.importLanguage()
  try {
    await translationStore.fetchLanguages()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!translationStore.selectedLanguage && translationStore.languages.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const [defaultLanguage] = translationStore.languages
      translationStore.selectLanguage(defaultLanguage.id)
    }
  } catch (e) {
    console.log(e)
  }
}

const handlePageReload = () => {
  authStore.setCurrentProfile(authStore.currentProfile)
}

const routeAppKey = computed(() => {
  const [routeKey = ''] = route.path.match(/\/[\w-]*/) || []
  return routeKey
})
onMounted(() => {
  window.addEventListener('beforeunload', handlePageReload)
})

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handlePageReload)
})
</script>

<style lang="scss">
@use '@/styles/global.scss';
html,
body,
.n-config-provider,
#app {
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
  margin: 0;
  padding: 0;
}
</style>

<style lang="scss" scoped>
.loaderWrapper {
  display: flex;
  height: 100%;
  justify-content: center;
}
</style>
