import { IConfigElementSimple } from '@/models/preset'

export const paymentsTableConfig: IConfigElementSimple = {
  name: 'Таблица платежей',
  type: 'TablePreset',
  widgetId: 'paymentsRegistry_table',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'колонка Флаги',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_flags',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Код',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_code',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Номер п/п',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_docNumber',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Дата п/п',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_docDate',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Поступило',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_incomingDate',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Плательщик',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_payerName',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Идентификаторы',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_payerCodes',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Сумма',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_sum',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Валюта п/п',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_docSumCurrency',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Сумма в валюте п/п',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_docSum',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Остаток',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_paymentRestSum',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Статус',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_paymentIdentificationStatus',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка Прим.',
      type: 'TableColumnPreset',
      widgetId: 'paymentsRegistry_column_note',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
  ],
}
