import { IConfigElementSimple } from '@/models/preset'
import { licenseDifferenceTable } from '@/views/licenses/config/licensesTabDifference/licensesTabDifferenceTable.frontconf'

export const licenseDifferenceTab: IConfigElementSimple = {
  name: 'Контейнер таба Обработка изменений',
  type: 'ContainerPreset',
  widgetId: 'licenseRegistry_tabs_differences_container',
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Модальное окно с изменениями лицензии',
      type: 'ContainerPreset',
      widgetId: 'licenseRegistry_tabs_differences_modalContainer',
      prefs: {
        label: null,
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'Заголовок таблицы с версиями изменений лицензии',
          type: 'TextPreset',
          widgetId: 'licenseRegistry_tabs_differences_versions_title',
          prefs: {
            label: 'Изменения',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        licenseDifferenceTable,
      ],
    },
    {
      name: 'Таблица таба Обработка изменений',
      type: 'TablePreset',
      widgetId: 'licenseRegistry_tabs_differences_table',
      prefs: {
        label: null,
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'колонка Дата',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_tabs_differences_column_date',
          prefs: {
            label: 'Дата',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Изменения',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_tabs_differences_column_differences',
          prefs: {
            label: 'Изменения',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Статус',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_tabs_differences_column_handlingStatus',
          prefs: {
            label: 'Статус',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_tabs_differences_column_buttons',
          prefs: {
            label: '',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
      ],
    },
  ],
}
