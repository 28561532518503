import { shallowRef, type Ref, isRef } from 'vue'
import { Nullable } from '@/models/helpers'

export type FetchResponse<T> = Nullable<T>

type ApiMethodType<R, T extends unknown[]> = (...args: T) => Promise<R>
export function useFetch<R, T extends unknown[]>(
  apiMethod: ApiMethodType<R, T>,
  isLoading?: Ref,
): {
  response: Ref<FetchResponse<R>>
  execute: ApiMethodType<R, T>
  error: Ref<unknown>
} {
  const response = shallowRef<FetchResponse<R>>(null)
  const error = shallowRef<unknown>(null)
  const execute = async (...args: Parameters<typeof apiMethod>): ReturnType<typeof apiMethod> => {
    if (isRef(isLoading)) {
      isLoading.value = true
    }
    response.value = null
    error.value = null

    try {
      const result = await apiMethod(...args)
      response.value = result
      return result
    } catch (fetchError) {
      error.value = fetchError
      throw fetchError
    } finally {
      if (isRef(isLoading)) isLoading.value = false
    }
  }

  return {
    response,
    execute,
    error,
  }
}
