import request, {
  IResponseEntity,
  IResponseList,
  IResponseListWithAggregations,
} from '@/api/request'
import {
  ISubjectV2,
  ISubjectAddressType,
  ISubjectPerson,
  ISubjectPersonCreate,
  ISubjectV1,
  ISubjectCreatedAddresses,
  ISubjectCreated,
  ISubjectDetailed,
} from '@/api/subjects/models'
import {
  ICreateSubjectAddressPayload,
  IGetIdentifierTypeMatchingSearchParams,
  IGetSuggessionsListParams,
  ISearchSubject,
  ISearchSubjectParams,
  ISubjectIdentifierTypeMatching,
  ISubjectSuggession,
  ISubjectType,
  IUpdateSubjectAddressPayload,
  ISubjectNoteCreate,
  ISubjectNote,
  ISubjectAddress,
} from '@/models/entities/subject'
import { ISubjectDocument, ISubjectDocumentCreate } from '@/models/entities/document'
import { ICreateSubjectAccountBodyParams, ISubjectAccount } from '@/models/entities/subjectAccount'
import { IEditSubjectData, ICreateSubjectData } from '@/components/forms/subjectForm/model'
import { FormModel } from '@/models/helpers'

export const getSubjectsListV2 = (params?: unknown) => {
  return request<IResponseList<ISubjectV2>>({
    url: '/api/v2/subject/subjects/detailed',
    method: 'GET',
    params,
  })
}

export const searchSubjects = (params?: ISearchSubjectParams) => {
  return request<IResponseListWithAggregations<ISearchSubject>>({
    url: '/api/v1/subject/subjects/search',
    method: 'GET',
    params,
  })
}

export const createSubject = (data: ICreateSubjectData) => {
  return request<IResponseEntity<ISubjectCreated>>({
    url: '/api/v1/subject/subjects/create',
    method: 'POST',
    data,
  })
}

export const updateSubject = (id: string | number, data: FormModel<IEditSubjectData>) => {
  return request<IResponseEntity<ISubjectCreated>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/${id}/update`,
    method: 'PUT',
    data,
  })
}

export const getSubject = (id: string | number) => {
  return request<IResponseEntity<ISubjectDetailed>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v2/subject/subjects/${id}/detailed`,
    method: 'GET',
  })
}

export const getSuggessionsList = (params?: IGetSuggessionsListParams) => {
  return request<ISubjectSuggession>({
    url: '/api/v1/subject/subjects_suggessions',
    method: 'GET',
    params,
  })
}

export const getTypesList = (params?: unknown) => {
  return request<IResponseList<ISubjectType>>({
    url: '/api/v1/subject/types',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getType = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/subject/subjects/types/${id}`,
    method: 'GET',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getRole = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/subject/subjects/roles/${id}`,
    method: 'GET',
  })
}

export const getIdentifiersList = (params?: IGetIdentifierTypeMatchingSearchParams) => {
  return request<IResponseList<ISubjectIdentifierTypeMatching>>({
    url: '/api/v1/subject/identifier_type_matchings',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getSubjectIdentifiers = params => {
  return request({
    url: '/api/v1/subject/subject_identifiers',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getIdentifier = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/identifiers/${id}`,
    method: 'GET',
  })
}

export const getSubjectsAccounts = (id: string | number, params?: unknown) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/${id}/accounts`,
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createSubjectsAccount = (data: ICreateSubjectAccountBodyParams) => {
  return request<IResponseEntity<ISubjectAccount>>({
    url: `/api/v1/subject/account`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const updateSubjectsAccount = data => {
  const { subjectId, accountId, ...payload } = data
  return request<IResponseEntity<ISubjectAccount>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/${subjectId}/account/${accountId}`,
    method: 'PUT',
    data: payload,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteSubjectsAccount = data => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/${data.subjectId}/account/${data.accountId}`,
    method: 'DELETE',
  })
}

export const getSubjectsAddresses = (subjectId: number | string, params?: unknown) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/${subjectId}/addresses`,
    method: 'GET',
    params,
  })
}

export const getSubjectsAddressTypes = (): Promise<{
  count: number
  list: ISubjectAddressType[]
  filters: unknown
}> => {
  return request({
    url: `/api/v1/subject/address_types`,
    method: 'GET',
  })
}

export const createSubjectAddress = (
  subjectId: string | null,
  data: ICreateSubjectAddressPayload,
) => {
  return request<IResponseList<ISubjectAddress>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/${subjectId}/addresses`,
    method: 'POST',
    data,
  })
}

export const updateSubjectAddress = (
  subjectId: ISubjectV1['id'],
  outerAddressId: ISubjectCreatedAddresses['addressId'],
  data: IUpdateSubjectAddressPayload,
) => {
  return request({
    url: `/api/v1/subject/subjects/${subjectId}/addresses/${outerAddressId}`,
    method: 'PUT',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteSubjectAddress = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/addresses/${id}`,
    method: 'DELETE',
  })
}

export const getSubjectPersons = (params?: unknown) => {
  return request<IResponseList<ISubjectPerson>>({
    url: `/api/v1/subject/persons`,
    method: 'GET',
    params,
  })
}

export const createSubjectPerson = (data: ISubjectPersonCreate) => {
  return request<IResponseEntity<ISubjectPerson>>({
    url: `/api/v1/subject/persons`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteSubjectPerson = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/persons/${id}`,
    method: 'DELETE',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getSubjectDocuments = params => {
  return request<IResponseList<ISubjectDocument>>({
    url: '/api/v1/subject/documents',
    method: 'GET',
    params,
  })
}

export const createSubjectDocument = (data: ISubjectDocumentCreate) => {
  return request<IResponseEntity<ISubjectDocument>>({
    url: '/api/v1/subject/documents',
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteSubjectDocument = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/documents/${id}`,
    method: 'DELETE',
  })
}

export const setSubjectNote = (subjectId: string | number, data: ISubjectNoteCreate) => {
  return request<IResponseEntity<ISubjectNote>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/subject/subjects/${subjectId}/set_note`,
    method: 'POST',
    data,
  })
}
