import { get, isArray, merge, mergeWith } from 'lodash'
import { filterObject, mapObject, walkObject, walkObjectRecursive } from '@/utils/objectUtils'

let modules = {}
const presetStore = {}

export const importPresets = () => {
  modules = Object.keys(modules).length
    ? modules
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      import.meta.glob('@/components/presets/**/!(__tests__)/*.vue')
  const promises = []
  for (const path in modules) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const p = modules[path]()
    promises.push(p)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    p.then(mod => {
      const presetKey = String(path)
        .replace(/^(?:[\w/.]+)?\/presets\//, '')
        .replace(/\//g, '.')
        .replace(/\.vue$/, '')
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      presetStore[presetKey] = mod.default ? mod.default : mod
    })
  }

  return Promise.allSettled(promises).then(() => {
    console.log(' * presetStore: ', presetStore)
    return presetStore
  })
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getPreset = presetName => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return presetStore[presetName]
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createNameFromKey = key =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  key.split('.').map(v => String(v)[0].toUpperCase() + String(v).substring(1).toLowerCase())

/**
 * filters config items if presence.hidden is true
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const filterItemsByPresence = items =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  items.filter(field => !(field?.presence?.hidden || false))

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getHelperContext = v => {
  const context = {
    fnName: '',
    fnArgs: [],
  }

  if (Array.isArray(v) && v.length) {
    const match = v[0].match(/^([a-z]+\w+)(?:\(\))?$/)
    if (match && match[1]) {
      context.fnName = match[1]
    }
    if (Array.isArray(v[1])) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      context.fnArgs = v[1]
    }
  } else if ((context.fnName = get(v, '__helper.fn', ''))) {
    context.fnArgs = get(v, '__helper.args', [])
  }

  return context
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const preparePresetParams = (params, helpers) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return mapObject(params, v => {
    const helperContext = getHelperContext(v)
    const helper =
      helperContext.fnName && typeof helpers[helperContext.fnName] === 'function'
        ? helpers[helperContext.fnName]
        : null
    return helper ? helper(...helperContext.fnArgs) : v
  })
}

// TODO: rename to contractedValue()
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const contractedValue = value => {
  if (typeof value === 'boolean') {
    return value
  }

  let result
  switch (value) {
    case 'true':
      result = true
      break
    case 'false':
      result = false
      break
    case '':
      result = null
      break
    default:
      result = value
  }
  return result
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const fixJSON = input => {
  const regex1 = /([,\{] *)(\w+):/g
  const regex2 = /([,\{] *"\w+":)(?! *-?[0-9\.]+[,\}])(?! *[\{\[])( *)([^,\}]*)/g
  return input
    .replaceAll("'", '"')
    .replace(regex1, '$1"$2":')
    .replace(regex2, '$1$2"$3"')
    .replace(/\"+/g, '"')
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const convertEntity = entity => {
  try {
    const {
      dataFieldPath: field,
      prefs: {
        hidden,
        disabled,
        editable,
        label,
        presetName: preset,
        presetParams: presetParamsSource,
      },
      children,
      ...meta
    } = entity

    // console.log(' * presetParamsSource : ', presetParamsSource)
    let presetParams
    try {
      presetParams = JSON.parse(
        fixJSON(
          [null, undefined].includes(presetParamsSource)
            ? '{}'
            : JSON.stringify(presetParamsSource),
        ),
      )
    } catch (exception) {
      presetParams = {}

      console.warn('(!) JSON.parse() :: ', exception, '\n input: ', presetParamsSource)
    }

    const result = {
      label,
      field,
      preset,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      presetParams: mapObject(presetParams, v => contractedValue(v)),
      presence: {
        hidden: contractedValue(hidden),
        disabled: contractedValue(disabled),
        editable: contractedValue(editable),
      },
      meta,
      // items: [],
    }

    if (Array.isArray(children) && children.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result.items = children.map(child => convertEntity(child))
    }
    return result
  } catch (e) {
    console.warn(' (!) convertEntity : ', e, '\n * entity : ', JSON.stringify(entity))
  }
}

export const mergeEntity = (
  { presetParams: localPresetParams = {}, presence: localPresence = {}, ...localConfRest },

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  { label, presetParams: apiPresetParams, presence: apiPresence = {}, ...apiConfRest },
) => {
  // const apiPresetParams2 = filterObject(apiPresetParams || {}, v => v !== null)
  // TODO: fix wrong presetParams after switch to other API response e.g. { items = [] }
  // const finalPresetParams = { ...localPresetParams, ...apiPresetParams2 }
  // console.log('apiConfRest', apiConfRest, 'localConfRest', localConfRest)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const apiPresence2 = filterObject(apiPresence || {}, v => v !== null)
  const finalPresetParams = { ...localPresetParams }
  const finalPresence = { ...localPresence, ...apiPresence2 }
  return {
    presetParams: finalPresetParams,
    presence: finalPresence,
    ...apiConfRest,
    ...localConfRest,
    label,
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const mergeConfig = (base, override, arrayMode = 'inplace') => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function arrayModifier(objValue, srcValue) {
    if (isArray(objValue)) {
      if (arrayMode === 'replace') {
        return srcValue
      } else if (arrayMode === 'append') {
        return objValue.concat(srcValue)
      }
    }
  }

  let target = Array.isArray(base) ? [] : {}
  return arrayMode === 'inplace'
    ? merge(target, base, override)
    : mergeWith(target, base, override, arrayModifier)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const mapObjectByWidgetId = object => {
  let widgetMap = {}

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const reducer = (val, key) => {
    let widgetId = get(val, 'meta.widgetId', '')
    if (widgetId) {
      const { items: _, ...itemConf } = val
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      widgetMap[widgetId] = itemConf
    }
    return { [key]: val }
  }

  walkObjectRecursive(object, reducer)

  return widgetMap
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const mergeConfigByWidget = (config, widgetMap = {}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const iterator = (val, key, returnAsObject = true) => {
    let extVal, newVal
    let widgetId = get(val, 'meta.widgetId', '')

    if (widgetId && Object.keys(widgetMap).includes(widgetId)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      extVal = widgetMap[widgetId]
      const extPresence = filterObject(
        extVal.presence,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        v => Boolean(Array.isArray(v) && v.length) || ![null, false].includes(v),
      )

      newVal = {
        ...val,
        label: extVal.label || val.label,
        field: extVal.field || val.field,
        presence: { ...val.presence, ...extPresence },
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (extPresence.hidden === true) {
        newVal.presence.hidden = true
      }

      return returnAsObject ? { [key]: newVal } : newVal
    }

    return returnAsObject ? { [key]: val } : val
  }
  return walkObject(config, iterator)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createPaneConfig = tabConf => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return tabConf.reduce((acc, tabEl) => {
    const tabId = tabEl?.presetParams?.tabId || ''
    if (tabId) {
      acc[tabId] = {
        items: (tabEl?.items || [])?.[0]?.items || [],
        presence: (tabEl?.items || [])?.[0]?.presence || {},
      }
    }
    return acc
  }, {})
}
