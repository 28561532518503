import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  IEvent,
  IEventGroup,
  IEventGroupCreate,
  IEventGroupQueryParams,
  IEventQueryParams,
  IEventPerformerName,
  IEventPerformerNameCreate,
  IEventPerformersNamesQueryParams,
  IEventPerformerType,
  IEventStatuses,
  IEventType,
  IEventCreateData,
  IEventExtendedResponse,
  IEventDetailed,
  IEventParameterValuesEditData,
  IEventParameterValuesEditResponse,
  IEventExtendedParameterValues,
  IEventEditData,
  IEventStatus,
  IEventStatusData,
  IEventGroupEvents,
} from '@/models/entities/event'
import { ICommonListQueryParams } from '@/models/entities/common'

export const searchEvents = (params?: IEventQueryParams) => {
  return request<IResponseList<IEvent>>({
    url: `/api/v1/event/events/search`,
    method: 'GET',
    params,
  })
}

export const getEvent = (id: IEvent['id']) => {
  return request<IResponseEntity<IEventDetailed>>({
    url: `/api/v1/event/events/${id}/detailed`,
    method: 'GET',
  })
}

export const createEvent = (data: IEventCreateData) => {
  return request<IEventExtendedResponse>({
    url: `/api/v1/event/events/create`,
    method: 'POST',
    data,
  })
}

export const editEvent = (id: IEventDetailed['id'], data: IEventEditData) => {
  return request<IResponseEntity<IEventDetailed>>({
    url: `/api/v1/event/events/${id}/update`,
    method: 'PUT',
    data,
  })
}

export const deleteEvent = (id: IEventGroup['id']) => {
  return request({
    url: `/api/v1/event/events/${id}/delete`,
    method: 'DELETE',
  })
}

export const editEventStatus = (id: IEventDetailed['id'], data: IEventStatusData) => {
  return request<IResponseEntity<IEventStatus>>({
    url: `/api/v1/event/events/${id}/set_status`,
    method: 'PUT',
    data,
  })
}

export const searchEventGroups = (params?: IEventGroupQueryParams) => {
  return request<IResponseList<IEventGroup>>({
    url: `/api/v1/event/groups/search`,
    method: 'GET',
    params,
  })
}

export const getEventGroup = (id: IEventGroup['id'], params?: ICommonListQueryParams) => {
  return request<IResponseEntity<IEventGroupEvents>>({
    url: `/api/v1/event/groups/${id}/events`,
    method: 'GET',
    params,
  })
}

export const createEventGroup = (data: IEventGroupCreate) => {
  return request<IResponseEntity<IEventGroup>>({
    url: `/api/v1/event/groups/create`,
    method: 'POST',
    data,
  })
}

export const editEventGroup = (id: IEventGroup['id'], data: IEventGroupCreate) => {
  return request<IResponseEntity<IEventGroup>>({
    url: `/api/v1/event/groups/${id}/update`,
    method: 'PUT',
    data,
  })
}

export const getEventTypes = (params?: ICommonListQueryParams) => {
  return request<IResponseList<IEventType>>({
    url: `/api/v1/event/types`,
    method: 'GET',
    params,
  })
}

export const deleteEventGroup = (id: IEventGroup['id']) => {
  return request({
    url: `/api/v1/event/groups/${id}/delete`,
    method: 'DELETE',
  })
}

export const getEventGroupsType = () => {
  return Promise.resolve({
    list: [
      {
        name: 'Тур',
        id: '1',
      },
    ],
  })
}

export const getPerformersNames = (params?: IEventPerformersNamesQueryParams) => {
  return request<IResponseList<IEventPerformerName>>({
    url: `/api/v1/event/performers_names/search`,
    method: 'GET',
    params,
  })
}

export const createPerformerName = (data: IEventPerformerNameCreate) => {
  return request<IResponseEntity<IEventPerformerName>>({
    url: `/api/v1/event/performers_names/create`,
    method: 'POST',
    data,
  })
}

export const editPerformerName = (
  id: IEventPerformerName['id'],
  data: IEventPerformerNameCreate,
) => {
  return request<IResponseEntity<IEventPerformerName>>({
    url: `/api/v1/event/performers_names/${String(id)}/update`,
    method: 'PUT',
    data,
  })
}

export const deletePerformerName = (id: IEventPerformerName['id']) => {
  return request({
    url: `/api/v1/event/performers_names/${String(id)}/delete`,
    method: 'DELETE',
  })
}

export const getPerformersTypes = (params?: ICommonListQueryParams) => {
  return request<IResponseList<IEventPerformerType>>({
    url: `/api/v1/event/events/performer_name_types`,
    method: 'GET',
    params,
  })
}

export const getEventStatuses = (params?: ICommonListQueryParams) => {
  return request<IResponseList<IEventStatuses>>({
    url: `/api/v1/event/statuses`,
    method: 'GET',
    params,
  })
}

export const getParameterValues = (id: IEvent['id']) => {
  return request<IResponseEntity<IEventExtendedParameterValues>>({
    url: `/api/v1/event/events/${id}/parameter_values`,
    method: 'GET',
  })
}

export const editParameterValues = (id: IEvent['id'], data: IEventParameterValuesEditData) => {
  return request<IResponseEntity<IEventParameterValuesEditResponse>>({
    url: `/api/v1/event/events/${id}/parameter_values`,
    method: 'PUT',
    data,
  })
}
