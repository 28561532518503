import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  IAddress,
  ICity,
  ICreateCityBodyData,
  ICreateRegionBodyData,
  IGetAddressesQueryParams,
  IGetCitiesQueryParams,
  IGetRegionsQueryParams,
  ISearchAddressesQueryParams,
  ISetAddressData,
  IUpdateCityBodyData,
} from '@/models/entities/address'

export const getAddresses = (params?: IGetAddressesQueryParams) => {
  return request<IResponseList<IAddress>>({
    url: '/api/v1/address/addresses',
    method: 'GET',
    params,
  })
}

export const searchAddress = (params: ISearchAddressesQueryParams) => {
  return request<IResponseList<IAddress>>({
    url: '/api/v1/address/addresses/search',
    method: 'GET',
    params,
  })
}

export const setAddress = (data: ISetAddressData) => {
  return request<IResponseEntity<IAddress>>({
    url: '/api/v1/external/address/addresses/set',
    method: 'POST',
    data,
  })
}

export const getAddress = (id: string | number) => {
  return request<IResponseEntity<IAddress>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/address/addresses/${id}`,
    method: 'GET',
  })
}

export const createRegion = (data: ICreateRegionBodyData) => {
  return request<IResponseEntity<IAddress['regionData']>>({
    url: `/api/v1/address/regions`,
    method: 'POST',
    data,
  })
}

export const getRegions = (params?: IGetRegionsQueryParams) => {
  return request<IResponseList<IAddress['regionData']>>({
    url: '/api/v1/address/regions',
    method: 'GET',
    params,
  })
}

export const updateRegion = (id = '', data: ICreateRegionBodyData) => {
  return request<IResponseEntity<IAddress['regionData']>>({
    url: `/api/v1/address/regions/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteRegion = (id = '') => {
  return request({
    url: `/api/v1/address/regions/${id}`,
    method: 'DELETE',
  })
}

export const getCities = (params?: IGetCitiesQueryParams) => {
  return request<IResponseList<ICity>>({
    url: `/api/v1/address/cities/detailed`,
    method: 'GET',
    params,
  })
}

export const deleteCity = (id: string = '') => {
  return request({
    url: `/api/v1/address/cities/${id}`,
    method: 'DELETE',
  })
}

export const updateCity = (id: string = '', data: IUpdateCityBodyData) => {
  return request<IResponseEntity<ICity>>({
    url: `/api/v1/external/address/cities/${id}`,
    method: 'PUT',
    data,
  })
}

export const createCity = (data: ICreateCityBodyData) => {
  return request<IResponseEntity<ICity>>({
    url: `/api/v1/external/address/cities`,
    method: 'POST',
    data,
  })
}
