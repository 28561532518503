import { unref, reactive, watch } from 'vue'
import { IPrefs, IAvailableElement } from '@/api/form/models'
import { isPlainObject, get } from 'lodash'

interface IPresetComputedValue {
  mod: string
  values: unknown[]
  positive: string
  negative: string
}

interface IDataForCalculationConfigField {
  configObjectWithProvideData: IPrefs
  configObjectReference: IPrefs
  configFieldName: string
  configFieldValueForCalculation?: IPresetComputedValue
}

interface IParamsForAddingFieldToWatch extends IDataForCalculationConfigField {
  providedFieldName: string
}

type ObjForTriggeringRecalculation = Record<string, IDataForCalculationConfigField[]>

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function useProcessConfig(config, allProvidedKeyValues) {
  const objForTriggeringRecalculation: ObjForTriggeringRecalculation = reactive({})

  function processConfig() {
    const reactiveConfig = reactive(processSingleWidgetConfig(config))
    watchAndRecalculateFields()
    return reactiveConfig
  }

  function processSingleWidgetConfig(widgetConfig: IAvailableElement): IAvailableElement {
    processConfigObject(widgetConfig.prefs)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (widgetConfig.children) {
      widgetConfig.children.forEach(child => {
        processSingleWidgetConfig(child)
      })
    }
    return widgetConfig // TODO: possibly return could be removed
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function processConfigObject(widgetConfigObject) {
    const fieldNamesToProcess = Object.keys(widgetConfigObject)
    fieldNamesToProcess.forEach(configFieldName => {
      if (isConfigValueMustBeComputed(widgetConfigObject[configFieldName])) {
        widgetConfigObject[configFieldName] = calculateConfigValue({
          configObjectWithProvideData: config.prefs,
          configObjectReference: widgetConfigObject,
          configFieldName,
          configFieldValueForCalculation: widgetConfigObject[configFieldName],
        })
      } else if (isPlainObject(widgetConfigObject[configFieldName])) {
        processConfigObject(widgetConfigObject[configFieldName])
      }
    })
  }

  function calculateConfigValue(
    params: IDataForCalculationConfigField,
    isRecalculate: boolean = false,
  ) {
    let {
      configObjectReference,
      configObjectWithProvideData,
      configFieldName,
      configFieldValueForCalculation,
    } = params
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const configValue = configFieldValueForCalculation || configObjectReference[configFieldName]
    if (isConfigValueMustBeComputed(configValue) || isRecalculate) {
      return getComputedValue({
        configObjectWithProvideData,
        configObjectReference,
        configFieldName,
        configFieldValueForCalculation,
      })
    }
    return configValue
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function isConfigValueMustBeComputed(configValue) {
    const valuesDeterminingComputedValue = ['mod', 'values', 'positive', 'negative']
    return (
      isPlainObject(configValue) &&
      valuesDeterminingComputedValue.every(key => key in configValue) &&
      Object.keys(allProvidedKeyValues).some(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        key => key === getVariableKeyFromConfigValues(configValue?.values)?.split('.')[0],
      )
    )
  }

  function getComputedValue(params: IDataForCalculationConfigField) {
    let {
      configObjectReference,
      configObjectWithProvideData,
      configFieldName,
      configFieldValueForCalculation,
    } = params
    const configFieldValue =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      configFieldValueForCalculation || configObjectReference[configFieldName]
    const valuesToCompare = prepareValuesForCompare({
      configObjectWithProvideData,
      configObjectReference,
      configFieldName,
      configFieldValueForCalculation,
    })
    switch (configFieldValue.mod) {
      case 'isEqual': {
        if (valuesToCompare[0] === valuesToCompare[1]) {
          return configFieldValue.positive
        }
        return configFieldValue.negative
      }
      case 'isNotEqual': {
        if (valuesToCompare[0] !== valuesToCompare[1]) {
          return configFieldValue.positive
        }
        return configFieldValue.negative
      }
      default: {
        return null
      }
    }
  }

  function getVariableKeyFromConfigValues(values: string[]): string {
    return values.reduce((variableKey, value) => {
      if (typeof value === 'string') {
        const [modifier, ...variable] = value.split('')
        if (modifier === '$') {
          variableKey = variable.join('')
        }
      }
      return variableKey
    }, '')
  }

  function prepareValuesForCompare(params: IDataForCalculationConfigField) {
    let {
      configObjectReference,
      configObjectWithProvideData,
      configFieldName,
      configFieldValueForCalculation,
    } = params
    const configFieldValue =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      configFieldValueForCalculation || configObjectWithProvideData[configFieldName]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const preparedValues = configFieldValue.values.map(value => {
      if (typeof value === 'string') {
        const [modifier, ...variable] = value.split('')

        if (modifier === '$') {
          const foundKey = variable.join('')
          addFieldToWatchObject({
            providedFieldName: foundKey,
            configObjectWithProvideData,
            configObjectReference,
            configFieldName,
            configFieldValueForCalculation,
          })
          if (foundKey.split('.').length > 1) {
            let [providedDataObject, ...pathInDataObject] = foundKey.split('.')
            return get(unref(allProvidedKeyValues[providedDataObject]), pathInDataObject.join('.'))
          }
          return unref(get(allProvidedKeyValues, foundKey))
        }
        return [modifier, variable].join('')
      }
      return value
    })
    return preparedValues
  }

  function addFieldToWatchObject(params: IParamsForAddingFieldToWatch) {
    let {
      providedFieldName,
      configObjectWithProvideData,
      configObjectReference,
      configFieldName,
      configFieldValueForCalculation,
    } = params
    const configFieldValue =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      configFieldValueForCalculation || configObjectReference[configFieldName]
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isFieldAlreadyBeingWatched = objForTriggeringRecalculation[providedFieldName]?.some(
      el => el.configFieldValueForCalculation === configFieldValue,
    )
    if (!isFieldAlreadyBeingWatched) {
      const fieldForRecalculation = {
        configObjectWithProvideData,
        configObjectReference,
        configFieldName,
        configFieldValueForCalculation: { ...configFieldValue },
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (objForTriggeringRecalculation[providedFieldName]) {
        objForTriggeringRecalculation[providedFieldName] = [
          ...objForTriggeringRecalculation[providedFieldName],
          fieldForRecalculation,
        ]
      } else {
        objForTriggeringRecalculation[providedFieldName] = [fieldForRecalculation]
      }
    }
  }

  function watchAndRecalculateFields() {
    Object.keys(objForTriggeringRecalculation).forEach(injectedKey => {
      watch(allProvidedKeyValues[injectedKey], () => {
        recalculateConfigFieldsDependedOnInjectedKey(injectedKey)
      })
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function recalculateConfigFieldsDependedOnInjectedKey(injectedKey) {
    objForTriggeringRecalculation[injectedKey].forEach(fieldToRecalculate => {
      let {
        configObjectReference,
        configObjectWithProvideData,
        configFieldName,
        configFieldValueForCalculation,
      } = fieldToRecalculate
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      configObjectReference[configFieldName] = calculateConfigValue(
        {
          configObjectReference,
          configObjectWithProvideData,
          configFieldName,
          configFieldValueForCalculation,
        },
        true,
      )
    })
  }

  return processConfig()
}

export default useProcessConfig
