export enum OPERATION_TYPE {
  IDENTIFICATION = 1,
  COMPARE = 2,
  DUTY = 3,
  ANOTHER = 4,
  EXCLUDE = 5,
  INCLUDE = 6,
}

export enum OPERATION_SUBTYPE {
  EXCLUDED = 4,
}

export enum OPERATION_STATUS {
  TICKET = 1,
  IDENTIFICATION = 2,
}
