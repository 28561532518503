<template>
  <n-button
    class="hg3-icon-button"
    text
    :circle="circle"
    :quaternary="quaternary"
    :ghost="ghost"
    :type="type"
    :size="size"
    :disabled="disabled"
    @click="emit('click')"
  >
    <template #icon>
      <n-icon
        size="25"
        :component="iconComponent"
      />
    </template>
  </n-button>
</template>

<script setup>
defineProps({
  iconComponent: {
    type: [Function, Object],
    default: () => null,
  },
  disabled: {
    type: Boolean,
  },
  quaternary: Boolean,
  circle: Boolean,
  ghost: Boolean,
  size: {
    type: String,
    default: 'medium',
  },
  type: {
    type: String,
    default: 'default',
  },
})

const emit = defineEmits(['click'])
</script>

<style lang="scss" scoped>
.hg3-icon-button {
  cursor: pointer !important;
}
</style>
