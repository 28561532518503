import { addressesTab } from '@/views/thesaurus/departments/config/departmentsTabAddressesTable.frontconf'
import departmentsAteTabTest from '@/views/thesaurus/departments/config/departmentsTabAte.frontconf'
import { IConfigElementSimple } from '@/models/preset'

export const departmentRegistryConfig: IConfigElementSimple = {
  name: 'Реестр подразделений',
  type: 'RegistryPage',
  widgetId: 'departments_registry',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Кнопка Группы',
      type: 'ButtonPreset',
      widgetId: 'departments_registry_controls_groups',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Кнопка Создать',
      type: 'ButtonPreset',
      widgetId: 'departments_registry_controls_create',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Информационная строка реестр подразделений',
      type: 'SearchStringPreset',
      widgetId: 'departments_search',
      prefs: {
        label: 'Искать или фильтровать',
        hidden: 'true',
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'фильтр АТЕ',
          type: 'SearchStringItemPreset',
          widgetId: 'departments_filter_name',
          dataFieldPath: 'filters.regionIds.values',
          prefs: {
            label: 'АТЕ',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              queryOperators: [
                {
                  field: 'filters.regionIds.op',
                  label: '=',
                  value: 'in',
                  default: true,
                },
              ],
              interactive: true,
              filterOptions: {
                fetcher: 'FETCH_ADDRESS_REGIONS',
              },
            },
          },
          children: [],
        },
      ],
    },
    {
      name: 'Таблица реестра подразделений',
      type: 'TablePreset',
      widgetId: 'departments_table',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'Код',
          type: 'TableColumnPreset',
          widgetId: 'departments_column_code',
          prefs: {
            label: 'Код',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              width: '75',
            },
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'departments_column_name',
          prefs: {
            label: 'Наименование',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              width: '600',
            },
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'departments_column_status_name',
          prefs: {
            label: 'Статус',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              width: '110',
            },
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'departments_column_groups_name',
          prefs: {
            label: 'Группа подразделений',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              width: '300',
            },
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'departments_column_contact_tel',
          prefs: {
            label: 'Телефон',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              width: '130',
            },
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'departments_column_contact_mail',
          prefs: {
            label: 'E-mail',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              width: '270',
            },
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'departments_column_buttons',
          prefs: {
            label: '',
            hidden: 'false',
            disabled: null,
            editable: null,
            options: {
              rowControls: {
                isEditable: 'true',
                isDeletable: 'true',
              },
              minWidth: '80px',
            },
          },
          children: [],
        },
      ],
    },
    {
      name: 'Блок табов Реестр подразделений',
      type: 'TabsPreset',
      widgetId: 'departments_tabs_tabs',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'таб Адреса',
          type: 'TabsItemPreset',
          widgetId: 'departments_tabs_addresses',
          prefs: {
            label: 'Адреса',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [addressesTab],
        },
        {
          name: 'таб Ограничения по АТЕ',
          type: 'TabsItemPreset',
          widgetId: 'departments_tabs_ate_regions',
          prefs: {
            label: 'Ограничения по АТЕ',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [departmentsAteTabTest],
        },
      ],
    },
  ],
}
