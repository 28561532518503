import { INetSourceType } from '@/models/entities/netSourceType'
import request, { IResponseEntity, IResponseList } from '../request'

export const getNetSourceTypes = (params?: unknown) => {
  return request<IResponseList<INetSourceType>>({
    url: '/api/v1/useobject/net_source_types',
    params,
  })
}

export const createNetSourceType = (data: Omit<INetSourceType, 'id'> | null) => {
  return request<IResponseEntity<INetSourceType>>({
    url: '/api/v1/useobject/net_source_types',
    method: 'POST',
    data,
  })
}

export const updateNetSourceType = (
  id: INetSourceType['id'],
  data: Omit<INetSourceType, 'id'> | null,
) => {
  return request<IResponseEntity<INetSourceType>>({
    url: `/api/v1/useobject/net_source_types/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteNetSourceType = (id: INetSourceType['id']) => {
  return request<IResponseEntity<INetSourceType>>({
    url: `/api/v1/useobject/net_source_types/${id}`,
    method: 'DELETE',
  })
}
