/* eslint-disable @typescript-eslint/ban-ts-comment */
import { provide, inject, computed, isRef, Ref, ref, reactive, unref } from 'vue'
import { CONFIG_ELEMENT_TYPES, IConfigElement } from '@/models/preset'
import { useEventBus } from '@/composables/useEventBus'
import useProcessConfig from '@/composables/useProcessConfig'

interface IInjectedState<T = unknown> {
  state: Record<string, Ref<T>>

  events: Record<string, (payload?: T) => void>
  bus: ReturnType<typeof useEventBus> | null
}

// Move to utils
const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

export function usePreset<T = unknown>(
  config: IConfigElement<CONFIG_ELEMENT_TYPES>,
  data: Record<string, T> = {},
) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!config) {
    console.warn('Configuration is not defined')
    return {
      state: {},
    }
  }
  const processData = (
    value: unknown,
    data: Record<string, unknown>,
    field: string,
    localState: Record<string, Ref<unknown>>,
  ) => {
    return computed({
      get() {
        if (typeof value === 'string') {
          const [modifier, ...variable] = value.split('')

          if (modifier === '$') {
            const foundKey = Object.keys(data).find(el => el === variable.join(''))

            // @ts-ignore
            return data[foundKey]
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!localState[field]?.value) {
          localState[field] = ref(value)
        }

        return localState[field]
      },
      set(val) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (localState[field]) {
          return (localState[field].value = val)
        }
        // make it better maybe?
        if (typeof value === 'string') {
          const [modifier, ...variable] = value.split('')

          if (modifier === '$') {
            const foundKey = Object.keys(data).find(el => el === variable.join(''))

            // @ts-ignore
            if (isRef(data[foundKey])) {
              // @ts-ignore
              return (data[foundKey].value = val)
            }

            // @ts-ignore
            return (data[foundKey] = val)
          }
        }
      },
    })
  }

  const allProvidedKeyValues = reactive({})
  let reactiveConfig
  function processProvide() {
    const localState = {}

    // @ts-ignore
    Object.entries(config.prefs.options?.state?.provide || {}).forEach(([field, value]) => {
      const proccesedFieldData = processData(value, data, field, localState)
      if (proccesedFieldData.value && isRef(proccesedFieldData.value)) {
        // @ts-ignore
        allProvidedKeyValues[field] = proccesedFieldData
        provide(field, {
          [field]: proccesedFieldData.value,
          ['update' + capitalize(field)]: (payload: unknown) => {
            processData(value, data, field, localState).value = payload
          },
        })
      }
    })

    if (data.bus) {
      provide('bus', data.bus)
    }
    reactiveConfig = useProcessConfig(config, allProvidedKeyValues)
  }

  function processInject(): IInjectedState<T> {
    // @ts-ignore
    const configData = Object.entries(config.prefs.options?.state?.inject || {}).reduce(
      // @ts-ignore
      (acc, [inner, outer]: [string, string]) => {
        const cur = inject(outer)
        if (cur) {
          // @ts-ignore
          acc.state[inner] = cur[outer]

          // @ts-ignore
          acc.events['update' + capitalize(inner)] = cur['update' + capitalize(outer)]
        }
        return acc
      },
      { events: {}, state: {} },
    )

    const bus: ReturnType<typeof useEventBus> | null = inject('bus', null)

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-misused-spread
    return { ...configData, bus }
  }

  const unrefState = (state: IInjectedState<T>['state']) =>
    Object.entries(state).reduce(
      (unreffedObject, [key, value]) => ({
        ...unreffedObject,
        [key]: unref(value),
      }),
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      {} as Record<string, T>,
    )

  processProvide()
  const injected = processInject()
  return { ...injected, reactiveConfig, unrefState }
}
