import { IConfigElementSimple } from '@/models/preset'
import { paymentsTabAttributesConfig } from '@/views/payments/config/paymentsTabAttributes.frontconf'
import { paymentsTabOperationsConfig } from '@/views/payments/config/paymentTabOperations.frontconf'

export const paymentsTabsBlockConfig: IConfigElementSimple = {
  name: 'Блок табов Реестр платежей',
  type: 'TabsPreset',
  widgetId: 'paymentsRegistry_tabs',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'таб Атрибуты платежа',
      type: 'TabsItemPreset',
      widgetId: 'paymentsRegistry_tabs_payment_attributes',
      prefs: {
        label: 'Атрибуты платежа',
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [paymentsTabAttributesConfig, paymentsTabOperationsConfig],
    },
  ],
}
