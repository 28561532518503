import { identificationExceptionsRuleTypeList } from '@/api/payment/mock'
import {
  ICreateIdentificationExceptionData,
  ICreateIdentificationExceptionResponse,
  IEditIdentificationExceptionData,
  IEditIdentificationExceptionResponse,
  IGetIdentificationExceptionsResponse,
  IIdentificationExceptionsRuleType,
} from '@/api/payment/models/identificationExceptions'
import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  ICreateOperationBodyParams,
  IEditOperationBodyParams,
  IGetPaymentAvailableSumQueryParams,
  IGetPaymentDenyReasonsQueryParams,
  IGetPaymentFlagTypesQueryParams,
  IGetPaymentOperationsQueryParams,
  IGetPaymentOperationSubtypesQueryParams,
  IPayment,
  IPaymentAvailableSum,
  IPaymentDenyReason,
  IPaymentFlagType,
  IPaymentNote,
  IPaymentNoteCreateBodyParams,
  IPaymentNoteCreateResponse,
  IPaymentNoteEditBodyParams,
  IPaymentOperation,
  IPaymentOperationDenyBodyParams,
  IPaymentOperationOperationSubtype,
  ISearchPaymentsQueryParams,
} from '@/models/entities/payment'
import {
  IGetPaymentIdentificationHistoryContractsQueryParams,
  IIdentificationHistoryContract,
  IPaymentIdentification,
  IPaymentIdentificationStatus,
} from '@/models/entities/paymentIdentification'
import { paymentsConfig } from '@/views/payments/config/paymentsRegistry.frontconf'

export const getPaymentsRegistryConfig = () => {
  return Promise.resolve(paymentsConfig)
}

export const getIdentificationExceptionsRuleTypes = (): Promise<
  Record<string, IIdentificationExceptionsRuleType[]>
> => Promise.resolve({ list: identificationExceptionsRuleTypeList })

export const searchPayments = (params?: ISearchPaymentsQueryParams) => {
  return request<IResponseList<IPayment>>({
    url: '/api/v1/payment/payment/payments/search',
    method: 'GET',
    params,
  })
}

export const getPaymentOperations = (params?: IGetPaymentOperationsQueryParams) => {
  return request<IResponseList<IPaymentOperation>>({
    url: '/api/v1/payment/payment/operations',
    method: 'GET',
    params,
  })
}

export const createPaymentOperation = (data: ICreateOperationBodyParams) => {
  return request<IResponseEntity<IPaymentOperation>>({
    url: '/api/v1/payment/payment/operations/create',
    method: 'POST',
    data,
  })
}

export const editPaymentOperation = (
  id: IPaymentOperation['id'],
  data: IEditOperationBodyParams,
) => {
  return request<IResponseEntity<IPaymentOperation>>({
    url: `/api/v1/payment/payment/operations/${id}`,
    method: 'PUT',
    data,
  })
}

export const deletePaymentOperation = (data: IPaymentOperationDenyBodyParams) => {
  return request({
    url: `/api/v1/payment/payment/operations/deny`,
    method: 'POST',
    data,
  })
}

export const getPaymentIdentificationStatus = (params?: unknown) => {
  return request<IResponseList<IPaymentIdentificationStatus>>({
    url: '/api/v1/payment/identification_status',
    method: 'GET',
    params,
  })
}

export const getPaymentIdentifications = (params?: unknown) => {
  return request<IResponseList<IPaymentIdentification>>({
    url: '/api/v1/payment/payment/identified_sum',
    method: 'GET',
    params,
  })
}

export const getIdentificationExceptions = (
  params?: unknown,
): Promise<IGetIdentificationExceptionsResponse> => {
  return request({
    url: '/api/v1/payment/identification_exception',
    method: 'GET',
    params,
  })
}

export const createIdentificationException = (
  data: ICreateIdentificationExceptionData,
): Promise<ICreateIdentificationExceptionResponse> => {
  return request({
    url: '/api/v1/payment/identification_exception',
    method: 'POST',
    data,
  })
}

export const editIdentificationException = (
  id: string | number,
  data: IEditIdentificationExceptionData,
): Promise<IEditIdentificationExceptionResponse> => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/payment/identification_exception/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteIdentificationException = (id: number): Promise<unknown> => {
  return request({
    url: `/api/v1/payment/identification_exception`,
    method: 'DELETE',
    params: { id },
  })
}

export const createPaymentNote = (
  paymentId: IPayment['id'],
  data: IPaymentNoteCreateBodyParams,
) => {
  return request<IResponseEntity<IPaymentNoteCreateResponse>>({
    url: `/api/v1/payments/${paymentId}/set-note`,
    method: 'POST',
    data,
  })
}

export const editPaymentNote = (noteId: IPaymentNote['id'], data: IPaymentNoteEditBodyParams) => {
  return request<IResponseEntity<IPaymentNoteCreateResponse>>({
    url: `/api/v1/payment/payment_notes/${noteId}`,
    method: 'PUT',
    data,
  })
}

export const getFlagTypes = (params?: IGetPaymentFlagTypesQueryParams) => {
  return request<IResponseList<IPaymentFlagType>>({
    url: '/api/v1/payment/flag_types',
    method: 'GET',
    params,
  })
}

export const getOperationSubtypes = (params?: IGetPaymentOperationSubtypesQueryParams) => {
  return request<IResponseList<IPaymentOperationOperationSubtype>>({
    url: '/api/v1/payment/payment/operation_subtypes',
    method: 'GET',
    params,
  })
}

export const getIdentificationHistoryContracts = (
  payerCode: IPayment['payerCode1'],
  params?: IGetPaymentIdentificationHistoryContractsQueryParams,
) => {
  return request<IResponseList<IIdentificationHistoryContract>>({
    url: `/api/v1/payment/payment/operations/identification_history_contracts/${payerCode}`,
    method: 'GET',
    params,
  })
}

export const getPaymentDenyReasons = (params?: IGetPaymentDenyReasonsQueryParams) => {
  return request<IResponseList<IPaymentDenyReason>>({
    url: '/api/v1/payment/payment/deny_reasons',
    method: 'GET',
    params,
  })
}

export const getPaymentAvailableSum = (params?: IGetPaymentAvailableSumQueryParams) => {
  return request<IResponseEntity<IPaymentAvailableSum>>({
    url: '/api/v1/payment/payment/payment/available_sums',
    method: 'GET',
    params,
  })
}
