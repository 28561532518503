import { OPERATION_TYPE } from '@/constants/paymentOperations'
import { IConfigElementSimple } from '@/models/preset'

export const paymentsSearchStringConfig: IConfigElementSimple = {
  name: 'Информационная строка реестр платежей',
  type: 'SearchStringPreset',
  widgetId: 'paymentsRegistry_searchString',
  prefs: {
    label: 'Искать или фильтровать',
    hidden: 'true',
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'фильтр Поиск',
      type: 'SearchStringItemPreset',
      widgetId: 'paymentsRegistry_searchString_filter_search',
      dataFieldPath: 'filters.search.value',
      prefs: {
        label: 'Поиск',
        hidden: null,
        disabled: null,
        editable: null,
        options: {
          queryOperators: [
            {
              field: 'filters.search.op',
              label: '=',
              value: 'contains',
              default: true,
            },
          ],
          interactive: false,
        },
      },
      children: [],
    },
    {
      name: 'фильтр Номер п/п',
      type: 'SearchStringItemPreset',
      widgetId: 'paymentsRegistry_searchString_filter_docNumber',
      dataFieldPath: 'filters.docNumber.value',
      prefs: {
        label: 'Номер п/п',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          queryOperators: [
            {
              field: 'filters.docNumber.op',
              label: '=',
              value: 'eq',
              default: true,
            },
          ],
          interactive: true,
        },
      },
      children: [],
    },
    {
      name: 'фильтр Валюта п/п',
      type: 'SearchStringItemPreset',
      widgetId: 'paymentsRegistry_searchString_filter_docCurrencyId',
      dataFieldPath: 'filters.docCurrencyId.value',
      prefs: {
        label: 'Валюта п/п',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          queryOperators: [
            {
              field: 'filters.docCurrencyId.op',
              label: '=',
              value: 'eq',
              default: true,
            },
          ],
          interactive: true,
          filterOptions: {
            fetcher: 'FETCH_CURRENCIES',
          },
        },
      },
      children: [],
    },
    {
      name: 'фильтр Счёт получателя',
      type: 'SearchStringItemPreset',
      widgetId: 'paymentsRegistry_searchString_filter_receiverAccount',
      dataFieldPath: 'filters.receiverAccountIds.values',
      prefs: {
        label: 'Счёт получателя',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          queryOperators: [
            {
              field: 'filters.receiverAccountIds.op',
              label: '=',
              value: 'in',
              default: true,
            },
          ],
          interactive: true,
          filterOptions: {
            fetcher: 'FETCH_RECEIVER_ACCOUNTS',
            remote: true,
            remoteQueryParam: {
              field: 'filters.name.value',
            },
            remoteQueryOperators: [
              {
                field: 'filters.name.op',
                value: 'contains',
              },
            ],
          },
        },
      },
      children: [],
    },
    {
      name: 'фильтр Флаги',
      type: 'SearchStringItemPreset',
      widgetId: 'paymentsRegistry_searchString_filter_flags',
      dataFieldPath: 'filters.flags.values',
      prefs: {
        label: 'Флаги',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          queryOperators: [
            {
              field: 'filters.flags.op',
              label: '=',
              value: 'in',
              default: true,
            },
          ],
          interactive: true,
          filterOptions: {
            fetcher: 'FETCH_PAYMENT_FLAGS',
          },
        },
      },
      children: [],
    },
    {
      name: 'фильтр Статья учёта прочих сборов',
      type: 'SearchStringItemPreset',
      widgetId: 'paymentsRegistry_searchString_filter_operationSubtypeAnother',
      dataFieldPath: 'filters.operationSubtypeIds.values',
      prefs: {
        label: 'Статья учёта прочих сборов',
        hidden: 'false',
        disabled: null,
        editable: null,
        options: {
          queryOperators: [
            {
              field: 'filters.operationSubtypeIds.op',
              label: '=',
              value: 'in',
              default: true,
            },
          ],
          interactive: true,
          filterOptions: {
            fetcher: 'FETCH_PAYMENT_OPERATION_SUBTYPES',
            defaultQueryParams: [
              {
                field: 'filters.operationTypeId.value',
                value: String(OPERATION_TYPE.ANOTHER),
              },
              {
                field: 'filters.operationTypeId.op',
                value: 'eq',
              },
            ],
          },
        },
      },
      children: [],
    },
  ],
}
