import { IConfigElementSimple } from '@/models/preset'
import { drawerIdentificationConfig } from '@/views/payments/config/drawerIdentification.frontconf.ts'
import { paymentsSearchStringConfig } from '@/views/payments/config/paymentsSearch.frontconf.ts'
import { paymentsTableConfig } from '@/views/payments/config/paymentsTable.frontconf.ts'
import { paymentsTabsBlockConfig } from '@/views/payments/config/paymentsTabs.frontconf.ts'

export const paymentsConfig: IConfigElementSimple = {
  name: 'Реестр платежей',
  type: 'RegistryPage',
  widgetId: 'paymentsRegistry',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    paymentsSearchStringConfig,
    paymentsTableConfig,
    paymentsTabsBlockConfig,
    drawerIdentificationConfig,
  ],
}
