import qs from 'qs'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import api from '@/api'
import { AuthStore, useAuthStore } from '@/stores/auth'
import { UserSettingsStore, useUserSettingsStore } from '@/stores/settings'
import { Breadcrumb, setPageTitle } from '@/utils/baseHelpers'
import { saveForwardRoute, getProfileFromRouteQuery, PROFILE_CODES } from '@/utils/routerHelpers'
import { LOGIN_ROUTE_NAME, PROFILES_ROUTE_NAME } from '@/router/constants'
import NotFound from '@/views/service/NotFound.vue'
import { cloneDeep, omit, isEqual } from 'lodash'
import { IProfile } from '@/models/auth'

declare module 'vue-router' {
  interface RouteMeta {
    breadcrumb?: Breadcrumb[]
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
  // temp solution
  {
    path: '/',
    name: 'Base',
    redirect: () => ({ name: 'ObjectRegistry' }),
  },
  {
    path: '/object_form/:id?',
    name: 'ObjectForm',
    component: () => import('@/views/objects/components/ObjectsFormWrapper.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/subject_form/:id?',
    name: 'SubjectForm',
    component: () => import('@/views/subjects/components/SubjectsForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/object_registry',
    name: 'ObjectRegistry',
    component: () => import('@/views/objects/ObjectsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'objectRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Объекты',
            },
          },
        },
      ],
    },
  },
  {
    path: '/bank_accounts',
    name: 'BankAccounts',
    component: () => import('@/views/thesaurus/bankAccounts/BankAccountsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'accounts',
          text: {
            type: 'simple',
            options: {
              value: 'Счета получателя',
            },
          },
        },
      ],
    },
  },
  {
    path: '/bank_account_form/:id?',
    name: 'BankAccountForm',
    component: () => import('@/views/thesaurus/bankAccounts/components/BankAccountsForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/bank_registry',
    name: 'BanksRegistry',
    component: () => import('@/views/thesaurus/banks/BanksRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'bankRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Банки',
            },
          },
        },
      ],
    },
  },
  {
    path: '/subject_registry',
    name: 'SubjectRegistry',
    component: () => import('@/views/subjects/SubjectsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'subjectRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Субъекты',
            },
          },
        },
      ],
    },
  },
  {
    path: '/object_registry/:id/:tab?',
    name: 'ObjectRegistryCard',
    component: () => import('@/views/objects/ObjectsCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'objects',
          text: {
            type: 'simple',
            options: {
              value: 'Объекты',
            },
          },
          to: { name: 'ObjectRegistry' },
        },
        {
          name: 'objectName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'name',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('@/views/contracts/ContractsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'registries',
          text: {
            type: 'simple',
            options: {
              value: 'Реестры',
            },
          },
        },
        {
          name: 'documents',
          text: {
            type: 'simple',
            options: {
              value: 'Все документы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: () => import('@/views/contracts/ContractsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'registries',
          text: {
            type: 'simple',
            options: {
              value: 'Реестры',
            },
          },
        },
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Договоры',
            },
          },
        },
      ],
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/contracts/ContractsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'registries',
          text: {
            type: 'simple',
            options: {
              value: 'Реестры',
            },
          },
        },
        {
          name: 'projects',
          text: {
            type: 'simple',
            options: {
              value: 'Проекты',
            },
          },
        },
      ],
    },
  },
  {
    path: '/compensations',
    name: 'Compensations',
    component: () => import('@/views/contracts/ContractsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'registries',
          text: {
            type: 'simple',
            options: {
              value: 'Реестры',
            },
          },
        },
        {
          name: 'compensations',
          text: {
            type: 'simple',
            options: {
              value: 'Соглашения о компенсации',
            },
          },
        },
      ],
    },
  },
  {
    path: '/rewards',
    name: 'Rewards',
    component: () => import('@/views/contracts/ContractsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'registries',
          text: {
            type: 'simple',
            options: {
              value: 'Реестры',
            },
          },
        },
        {
          name: 'rewards',
          text: {
            type: 'simple',
            options: {
              value: 'Соглашения о вознаграждении',
            },
          },
        },
      ],
    },
  },
  {
    path: '/fixations',
    name: 'Fixations',
    component: () => import('@/views/claims/fixations/FixationsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'fixations',
          text: {
            type: 'simple',
            options: {
              value: 'Фиксации',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_fixation_card/:id',
    name: 'ContractsFixationCard',
    component: () => import('@/views/claims/fixations/FixationsCard.vue'),
    redirect: { name: 'Fixation' },
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'fixations',
          text: {
            type: 'simple',
            options: {
              value: 'Фиксации',
            },
          },
          to: { name: 'Fixations' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
    children: [
      {
        name: 'Fixation',
        path: 'fixation',
        component: () =>
          import(
            '@/views/claims/fixations/components/fixationsTabFixation/FixationsCardTabFixation.vue'
          ),
        meta: {
          layout: 'main-layout',
        },
      },
      {
        name: 'Log',
        path: 'log',
        component: () => import('@/views/claims/fixations/components/FixationsCardTabLog.vue'),
        meta: {
          layout: 'main-layout',
        },
      },
    ],
  },
  {
    path: '/contracts_card/:id/:tab?',
    name: 'ContractsCard',
    component: () => import('@/views/contracts/ContractsCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Договоры',
            },
          },
          to: { name: 'Contracts' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_card/:id/conflict/:conflictId/:tab?',
    name: 'ContractsConflictCard',
    component: () => import('@/views/contracts/ContractsConflictCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Договоры',
            },
          },
          to: { name: 'Contracts' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
          to: { name: 'ContractsCard' },
        },
        {
          name: 'conflict',
          text: {
            type: 'simple',
            options: {
              value: 'Конфликт',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_project_card/:id/:tab?',
    name: 'ContractsProjectCard',
    component: () => import('@/views/contracts/ContractsProjectCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Проекты',
            },
          },
          to: { name: 'Contracts' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_project_card/:id/conflict/:conflictId/:tab?',
    name: 'ContractsProjectConflictCard',
    component: () => import('@/views/contracts/ContractsConflictCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'contracts',
          text: {
            type: 'simple',
            options: {
              value: 'Проекты',
            },
          },
          to: { name: 'Contracts' },
        },
        {
          name: 'contractName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
          to: { name: 'ContractsProjectCard' },
        },
        {
          name: 'conflict',
          text: {
            type: 'simple',
            options: {
              value: 'Конфликт',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/contracts_form/:id?',
    name: 'ContractsForm',
    component: () => import('@/views/contracts/components/ContractsForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/payments-old',
    name: 'PaymentsOld',
    component: () => import('@/views/payments/PaymentsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'payments',
          text: {
            type: 'simple',
            options: {
              value: 'Платежи',
            },
          },
        },
      ],
    },
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('@/views/payments/PaymentsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'payments',
          text: {
            type: 'simple',
            options: {
              value: 'Платежи',
            },
          },
        },
      ],
    },
  },
  {
    path: '/payment_import_packages',
    name: 'PaymentImportPackages',
    component: () => import('@/views/paymentImportPackages/PaymentImportPackagesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'paymentImportPackages',
          text: {
            type: 'simple',
            options: {
              value: 'Загрузки поступлений',
            },
          },
        },
      ],
    },
  },
  {
    path: '/theme-table',
    name: 'ThemeTable',
    component: () => import('@/views/tech/theme/ThemeTable.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/login',
    name: LOGIN_ROUTE_NAME,
    component: () => import('@/views/auth/AuthLogin.vue'),
    meta: {
      layout: 'auth-layout',
      breadcrumb: [
        {
          name: 'login',
          text: {
            type: 'simple',
            options: {
              value: 'Вход',
            },
          },
        },
      ],
    },
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import('@/views/auth/AuthPasswordConfirm.vue'),
    meta: {
      layout: 'auth-layout',
      ignoreAuth: true,
    },
  },
  {
    path: '/profiles',
    name: PROFILES_ROUTE_NAME,
    component: () => import('@/views/auth/AuthProfiles.vue'),
    meta: {
      layout: 'auth-layout',
    },
  },
  {
    path: '/settings-ui',
    name: 'SettingsUI',
    component: () => import('@/views/admin/settings/SettingsUi.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'settingsUI',
          text: {
            type: 'simple',
            options: {
              value: 'Настройки UI',
            },
          },
        },
      ],
    },
  },
  {
    path: '/operators',
    name: 'Operators',
    component: () => import('@/views/admin/operators/OperatorsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'operators',
          text: {
            type: 'simple',
            options: {
              value: 'Операторы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/widget-configurations',
    name: 'WidgetConfigurations',
    component: () => import('@/views/admin/widgetConfigurations/WidgetConfigurationsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'admin',
          text: {
            type: 'simple',
            options: {
              value: 'Администрирование',
            },
          },
        },
        {
          name: 'widgetConfigurations',
          text: {
            type: 'simple',
            options: {
              value: 'Конфигурации интерфейса',
            },
          },
        },
      ],
    },
  },
  {
    path: '/preset-config',
    name: 'PresetsConfig',
    component: () => import('@/views/tech/presets/PresetsConfig.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'techPresets',
          text: {
            type: 'simple',
            options: {
              value: 'Пресеты',
            },
          },
        },
      ],
    },
  },
  {
    path: '/admin_config_create',
    name: 'AdminConfigCreate',
    component: () => import('@/views/tech/formConfig/FormConfigCreate.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'adminConfigCreate',
          text: {
            type: 'simple',
            options: {
              value: 'Создать конфигурацию формы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/admin_config_edit',
    name: 'AdminConfigEdit',
    component: () => import('@/views/tech/formConfig/FormConfigEdit.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'adminConfigEdit',
          text: {
            type: 'simple',
            options: {
              value: 'Изменить конфигурацию формы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/user_categories',
    name: 'UserCategories',
    component: () => import('@/views/thesaurus/userCategories/UserCategories.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'userCategories',
          text: {
            type: 'simple',
            options: {
              value: 'Категории пользователя',
            },
          },
        },
      ],
    },
  },
  {
    path: '/user_categories/management',
    name: 'UserCategoriesManagement',
    component: () => import('@/views/thesaurus/userCategories/UserCategoriesManagement.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'userCategoriesManagement',
          text: {
            type: 'simple',
            options: {
              value: 'Управление категориями пользователя',
            },
          },
        },
      ],
    },
  },
  {
    path: '/identification_exceptions',
    name: 'IdentificationExceptions',
    component: () =>
      import('@/views/thesaurus/identificationExceptions/IdentificationExceptionsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionary',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'IdentificationExceptionsRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Исключения из идентификации',
            },
          },
        },
      ],
    },
  },
  {
    path: '/translations/tokens',
    name: 'AdminTranslationsTokens',
    component: () => import('@/views/admin/translations/TranslationsTokenRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'admin',
          text: {
            type: 'simple',
            options: {
              value: 'Администрирование',
            },
          },
        },
        {
          name: 'translations',
          text: {
            type: 'simple',
            options: {
              value: 'Переводы',
            },
          },
        },
        {
          name: 'tokens',
          text: {
            type: 'simple',
            options: {
              value: 'Токены',
            },
          },
        },
      ],
    },
  },
  {
    path: '/payment_import_configurations',
    name: 'AdminPaymentImportConfigurations',
    component: () =>
      import('@/views/admin/paymentImportConfigurations/PaymentImportConfigurations.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'adminPaymentImportConfigurations',
          text: {
            type: 'simple',
            options: {
              value: 'Конфигурации загрузки',
            },
          },
        },
      ],
    },
  },
  {
    path: '/payment_import_configuration_form/:id?',
    name: 'PaymentImportConfigurationForm',
    component: () =>
      import('@/views/admin/paymentImportConfigurations/PaymentImportConfigurationForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/departments',
    name: 'DepartmentsRegistry',
    component: () => import('@/views/thesaurus/departments/DepartmentsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'departments',
          text: {
            type: 'simple',
            options: {
              value: 'Подразделения',
            },
          },
        },
      ],
    },
  },
  {
    path: '/departments_form/:id?',
    name: 'DepartmentsForm',
    component: () => import('@/views/thesaurus/departments/components/DepartmentsForm.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/documents_form/:id?',
    name: 'DocumentsCreateEditForm',
    component: () => import('@/views/documents/DocumentsFormCreateEdit.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
  {
    path: '/document-types',
    name: 'DocumentTypesRegistry',
    component: () => import('@/views/thesaurus/documentTypes/DocumentTypesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'documentTypes',
          text: {
            type: 'simple',
            options: {
              value: 'Типы документов',
            },
          },
        },
      ],
    },
  },
  {
    path: '/regions',
    name: 'Regions',
    component: () => import('@/views/thesaurus/regions/RegionsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'regions',
          text: {
            type: 'simple',
            options: {
              value: 'Регионы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/cities',
    name: 'Cities',
    component: () => import('@/views/thesaurus/cities/CitiesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'cities',
          text: {
            type: 'simple',
            options: {
              value: 'Города',
            },
          },
        },
      ],
    },
  },
  {
    path: '/net_source_types',
    name: 'NetSourceTypesRegistry',
    component: () => import('@/views/thesaurus/netSourceTypes/NetSourceTypesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'netSourceTypes',
          text: {
            type: 'simple',
            options: {
              value: 'Типы сетевых источников',
            },
          },
        },
      ],
    },
  },
  {
    path: '/positions',
    name: 'Positions',
    component: () => import('@/views/thesaurus/positions/PositionsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'positions',
          text: {
            type: 'simple',
            options: {
              value: 'Должности',
            },
          },
        },
      ],
    },
  },
  {
    path: '/translations',
    name: 'AdminTranslations',
    component: () => import('@/views/admin/translations/TranslationsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'admin',
          text: {
            type: 'simple',
            options: {
              value: 'Администрирование',
            },
          },
        },
        {
          name: 'translations',
          text: {
            type: 'simple',
            options: {
              value: 'Переводы',
            },
          },
        },
        {
          name: 'translations',
          text: {
            type: 'simple',
            options: {
              value: 'Переводы',
            },
          },
        },
      ],
    },
  },
  {
    path: '/useobject_groups',
    name: 'UseobjectGroups',
    component: () => import('@/views/thesaurus/useobjectGroups/UseobjectGroups.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'useobjectGroups',
          text: {
            type: 'simple',
            options: {
              value: 'Сети и группы объектов',
            },
          },
        },
      ],
    },
  },
  {
    path: '/subject_registry/:id/:tab?',
    name: 'SubjectRegistryCard',
    component: () => import('@/views/subjects/SubjectsCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'subjects',
          text: {
            type: 'simple',
            options: {
              value: 'Субъекты',
            },
          },
          to: { name: 'SubjectRegistry' },
        },
        {
          name: 'subjectName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/identification_configuration_registry',
    name: 'IdentificationConfigurationRegistry',
    component: () =>
      import(
        '@/views/thesaurus/identificationConfiguration/IdentificationConfigurationRegistry.vue'
      ),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionary',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'IdentificationConfigurationRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Конфигурация алгоритмов идентификации',
            },
          },
        },
      ],
    },
  },
  {
    path: '/licenses',
    name: 'LicensesRegistry',
    component: () => import('@/views/licenses/LicensesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'LicensesRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Лицензии СМИ',
            },
          },
        },
      ],
    },
  },
  {
    path: '/name-component-registry',
    name: 'NameComponentsRegistry',
    component: () => import('@/views/thesaurus/nameComponents/NameComponentsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionary',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'NameComponentsRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Компоненты наименований',
            },
          },
        },
      ],
    },
  },
  {
    path: '/conflict-cases',
    name: 'ConflictsRegistry',
    component: () => import('@/views/claims/conflicts/ConflictsRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'claimWork',
          text: {
            type: 'simple',
            options: {
              value: 'Претензионная работа',
            },
          },
        },
        {
          name: 'NameComponentsRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Конфликтные дела',
            },
          },
        },
      ],
    },
  },
  {
    path: '/conflict-cases/:id/:tabName?',
    name: 'ConflictsCard',
    component: () => import('@/views/claims/conflicts/ConflictsCard.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'ConflictsRegistry',
          text: {
            type: 'simple',
            options: {
              value: 'Конфликтные дела',
            },
          },
          to: { name: 'ConflictsRegistry' },
        },
        {
          name: 'bip',
          text: {
            type: 'simple',
            options: {
              value: 'БИП',
            },
          },
        },
        {
          name: 'conflictNumber',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
  },
  {
    path: '/performers-names',
    name: 'PerformersNames',
    component: () => import('@/views/thesaurus/performersNames/PerformersNamesRegistry.vue'),
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'ArtistsAndTitles',
          text: {
            type: 'simple',
            options: {
              value: 'Исполнители и наименования',
            },
          },
        },
      ],
    },
  },
  {
    path: '/event-groups',
    name: 'EventGroups',
    component: () => import('@/views/thesaurus/eventGroups/EventGroupsRegistry.vue'),
    redirect: { name: 'EventGroupsEvents' },
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'dictionaries',
          text: {
            type: 'simple',
            options: {
              value: 'Справочники',
            },
          },
        },
        {
          name: 'EventGroups',
          text: {
            type: 'simple',
            options: {
              value: 'Группа мероприятий',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
    children: [
      {
        name: 'EventGroupsEvents',
        path: 'events',
        component: () =>
          import(
            '@/views/thesaurus/eventGroups/components/eventGroupsTabEvents/EventGroupsTabEvents.vue'
          ),
        meta: {
          layout: 'main-layout',
        },
      },
    ],
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('@/views/events/EventsRegistry.vue'),
    redirect: { name: 'EventTabParameters' },
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'Events',
          text: {
            type: 'simple',
            options: {
              value: 'Мероприятия',
            },
          },
        },
      ],
    },
    children: [
      {
        name: 'EventTabParameters',
        path: 'parameters',
        component: () => import('@/views/events/components/EventsTabParameters.vue'),
        meta: {
          layout: 'main-layout',
        },
      },
    ],
  },
  {
    path: '/event-card/:id',
    name: 'EventsCard',
    component: () => import('@/views/events/EventsCard.vue'),
    redirect: { name: 'EventCardTabParameters' },
    meta: {
      layout: 'main-layout',
      breadcrumb: [
        {
          name: 'events',
          text: {
            type: 'simple',
            options: {
              value: 'Мероприятия',
            },
          },
          to: { name: 'Events' },
        },
        {
          name: 'eventName',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'displayName',
            },
          },
        },
        {
          name: 'tab',
          text: {
            type: 'fromTempStore',
            options: {
              field: 'tab',
            },
          },
        },
      ],
    },
    children: [
      {
        name: 'EventCardTabParameters',
        path: 'parameters',
        component: () => import('@/views/events/components/EventsCardTabParameters.vue'),
        meta: {
          layout: 'main-layout',
        },
      },
    ],
  },
  {
    path: '/notifications',
    name: 'NotificationsRegistry',
    component: () => import('@/views/notifications/NotificationsRegistry.vue'),
    meta: {
      layout: 'main-layout',
    },
  },
]

const router = createRouter({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  parseQuery(query) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return qs.parse(query, { arrayFormat: 'brackets' })
  },
  stringifyQuery(query) {
    return qs.stringify(query, { arrayFormat: 'brackets', encode: false })
  },
})

router.beforeEach(async (to, from, next) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setPageTitle(to.meta.breadcrumb)

  if (to.name) {
    saveForwardRoute(to)
  }

  const authStore: AuthStore = useAuthStore()
  const userSettingsStore: UserSettingsStore = useUserSettingsStore()

  if (!authStore.getIsAuth()) {
    if (to.meta.ignoreAuth || to.name === LOGIN_ROUTE_NAME) {
      next()
    } else {
      next({ name: LOGIN_ROUTE_NAME })
    }
  } else {
    const profileFromQuery: IProfile | undefined = getProfileFromRouteQuery(to.query)
    if (profileFromQuery) {
      authStore.saveSelectedProfileToLocalStorage(profileFromQuery)
    }

    if (!authStore.defaultProfile && to.name !== PROFILES_ROUTE_NAME) {
      authStore.isAppInitialazing = true
      try {
        await initializeUserProfile(authStore, userSettingsStore)
      } catch (error) {
        console.error('Error during profile initialization: ', error)
      } finally {
        authStore.isAppInitialazing = false
      }
    }

    let preparedLinkQuery = cloneDeep(to.query)
    const queryParamsToDelete = Object.values(PROFILE_CODES)
    preparedLinkQuery = omit(preparedLinkQuery, queryParamsToDelete)
    if (!isEqual(to.query, preparedLinkQuery)) {
      next({ path: to.path, query: preparedLinkQuery })
    } else {
      next()
    }
  }
})

async function initializeUserProfile(authStore: AuthStore, userSettingsStore: UserSettingsStore) {
  try {
    const profileResponse = await api.auth.getProfile()
    authStore.setDefaultProfile(profileResponse)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentProfileParams = JSON.parse(localStorage.getItem('currentProfile'))
    authStore.setCurrentProfile(currentProfileParams || profileResponse)

    await userSettingsStore.fetchUserSettings()
  } catch (error) {
    console.error(error)
    throw new Error('Failed to load user profile')
  }

  try {
    //TODO: поправить после реализации api
    // const contourResponse = await api.auth.getContourConfiguration()
    authStore.setContourConfiguration({
      configuration: {
        currency: {
          id: 643,
          code: 'RUB',
          name: 'trans.currency.names.RUB',
        },
        language: {
          id: 1,
          code: 'RUS',
        },
      },
    })
  } catch {
    console.warn('Contour configuration not loaded')
  }
}

export default router
